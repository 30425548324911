import {
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { User } from '../../../../../../../../models/user'
import { stringAndRoundTo2Decimals } from '../../../../../../../../Modules/DisputeModules/AppFunctions'
import { removeUnderscore } from '../../../../../../../../services/commonFunctions'
import {
  getLegalCosts,
  legalFeesZero,
} from '../../../../../../../../services/legalCostsFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  getSplitText,
  getText,
} from '../../../../../../../../services/textFunctions'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

type Props = {
  currentSnapshot: ScenarioSnapshot
  user: User
  legalCostsDescriptions: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
  forLegalCostsComponent?: boolean
}

export default function LegalCostsPreviewComponentDocument(props: Props) {
  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  const hasSecondTrial = props.currentSnapshot.probSecondTrial! > 0

  const firstProceedingsDefaultMessage = hasSecondTrial
    ? getText('title-129', props.user.settings)
    : getText('title-130', props.user.settings)

  const secondProceedingsDefaultMessage =
    props.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        getText('title-131a', props.user.settings)
      : //title-131b
        getText('title-131b', props.user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  const legalCostsObj = props.currentSnapshot.legalCosts

  const getLegalCostsSingleValue = (
    value: number | string,
    isPercentage?: boolean,
  ) => {
    if (typeof value === 'string') {
      return `${value} ${isPercentage ? '%' : props.currentSnapshot.currency}`
    }

    return `${stringAndRoundTo2Decimals(value, props.user.settings)} ${
      isPercentage ? '%' : props.currentSnapshot.currency
    }`
  }

  const styles = StyleSheet.create({
    paragraph: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      maxWidth: '450px',
      fontSize: '8px',
    },
    mainRow: {
      width: props.forLegalCostsComponent ? '450px' : '360px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: props.forLegalCostsComponent ? '8px' : '0px',
    },
    mainColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      width: '36%',
    },
    mainCenteredColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '32%',
    },
    smallColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    normalText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      textOverflow: 'ellipsis',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).marginTop,
      maxLines: 1,
    },
    semiBoldText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.medium,
        true,
      ).color,
      textOverflow: 'ellipsis',
      maxLines: 1,
    },
    boldText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.bold,
          true,
        ).fontFamily ?? 'Poppins-Bold',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.bold,
        true,
      ).color,
      textOverflow: 'ellipsis',
      maxLines: 1,
    },
  })

  return (
    <View wrap={false} style={{ width: '450px' }}>
      {legalFeesZero(props.currentSnapshot) ? (
        <Text
          style={styles.paragraph}
          id="noLegalsCostsParagraphForPdf"
          data-textaatribute="description-52"
        >
          {getText('description-52', props.user.settings)}
        </Text>
      ) : (
        <View style={styles.mainRow}>
          <View style={styles.mainColumn}>
            <Text
              style={{
                ...styles.normalText,
                marginBottom: '12px',
                color: 'transparent',
              }}
            >
              No Text
            </Text>
            <Text
              style={{
                ...styles.semiBoldText,
                marginBottom: '3px',
              }}
            >
              {firstProceedingsText.length > 0
                ? firstProceedingsText
                : firstProceedingsDefaultMessage}
              :
            </Text>
            <Text
              style={{
                ...styles.normalText,
                marginLeft: '12px',
              }}
            >
              {getText('label-89', props.user.settings)}:
            </Text>
            <Text
              style={{
                ...styles.normalText,
                marginLeft: '12px',
              }}
            >
              {getText('label-91', props.user.settings)}:
            </Text>
            {hasSecondTrial && (
              <>
                <Text
                  style={{
                    ...styles.semiBoldText,
                    marginBottom: '3px',
                    marginTop: '20px',
                  }}
                >
                  {secondProceedingsText.length > 0
                    ? secondProceedingsText
                    : secondProceedingsDefaultMessage}
                  :
                </Text>
                <Text
                  style={{
                    ...styles.normalText,
                    marginLeft: '12px',
                  }}
                >
                  {getText('label-89', props.user.settings)}:
                </Text>
                <Text
                  style={{
                    ...styles.normalText,
                    marginLeft: '12px',
                  }}
                >
                  {getText('label-91', props.user.settings)}:
                </Text>
              </>
            )}
            {props.forLegalCostsComponent &&
              props.currentSnapshot.hasInsurance && (
                <>
                  <Text
                    style={{
                      ...styles.semiBoldText,
                      marginBottom: '3px',
                      marginTop: '20px',
                    }}
                    data-textattribute="title-254"
                  >
                    {getSplitText(
                      `${removeUnderscore(partiesFormat[0])}${getText(
                        'title-254',
                        props.user.settings,
                      )}`,
                      '’s',
                      40,
                      10,
                    )}
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      marginLeft: '12px',
                    }}
                  >
                    {getText('label-36', props.user.settings)}:
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      marginLeft: '12px',
                    }}
                    data-textattribute="title-255"
                  >
                    {getText('title-255', props.user.settings)}:
                  </Text>
                  <Text
                    style={{
                      ...styles.normalText,
                      marginLeft: '12px',
                    }}
                    data-textattribute="title-256"
                  >
                    {getText('title-256', props.user.settings)}:
                  </Text>
                </>
              )}
          </View>
          <View style={styles.mainCenteredColumn}>
            <Text
              style={
                props.forLegalCostsComponent
                  ? {
                      ...styles.boldText,
                      marginBottom: '6px',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }
                  : {
                      ...styles.normalText,
                      marginBottom: '6px',
                    }
              }
            >
              {removeUnderscore(partiesFormat[0])}
            </Text>
            <View style={styles.smallColumn}>
              <Text
                style={{
                  ...styles.boldText,
                  marginBottom: '3px',
                }}
              >
                {getLegalCostsSingleValue(
                  legalCostsObj.clientLegalCosts.firstProceedings
                    .estimatedCosts!,
                )}
              </Text>
              <Text style={styles.normalText}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'recoverableCosts',
                    LegalCostsParty.clientLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                )}
              </Text>
              <Text style={styles.normalText}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'incurredCosts',
                    LegalCostsParty.clientLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                )}
              </Text>
              {hasSecondTrial && (
                <>
                  <Text
                    style={{
                      ...styles.boldText,
                      marginBottom: '3px',
                      marginTop: '20px',
                    }}
                  >
                    {getLegalCostsSingleValue(
                      legalCostsObj.clientLegalCosts.additionalProceedings!
                        .estimatedCosts!,
                    )}
                  </Text>
                  <Text style={styles.normalText}>
                    {getLegalCostsSingleValue(
                      getLegalCosts(
                        'recoverableCosts',
                        LegalCostsParty.clientLegalCosts,
                        LegalCostsProceedings.additionalProceedings,
                        props.currentSnapshot,
                        props.user.settings,
                      ),
                    )}
                  </Text>
                  <Text style={styles.normalText}>
                    {getLegalCostsSingleValue(
                      getLegalCosts(
                        'incurredCosts',
                        LegalCostsParty.clientLegalCosts,
                        LegalCostsProceedings.additionalProceedings,
                        props.currentSnapshot,
                        props.user.settings,
                      ),
                    )}
                  </Text>
                </>
              )}
              {props.forLegalCostsComponent &&
                props.currentSnapshot.hasInsurance && (
                  <>
                    <Text
                      style={{
                        ...styles.boldText,
                        color: 'transparent',
                        marginBottom: '3px',
                        marginTop: '20px',
                      }}
                    >
                      {'No Value'}
                    </Text>
                    <Text style={styles.normalText}>
                      {getLegalCostsSingleValue(
                        props.currentSnapshot.minimumExcessFee!,
                      )}
                    </Text>
                    <Text style={styles.normalText}>
                      {getLegalCostsSingleValue(
                        props.currentSnapshot.insuranceExcessPercentage!,
                        true,
                      )}
                    </Text>
                    <Text style={styles.normalText}>
                      {getLegalCostsSingleValue(
                        props.currentSnapshot.maxInsurance!,
                      )}
                    </Text>
                  </>
                )}
            </View>
          </View>
          <View style={styles.mainCenteredColumn}>
            <Text
              style={
                props.forLegalCostsComponent
                  ? {
                      ...styles.boldText,
                      marginBottom: '6px',
                      marginLeft: '3px',
                      marginRight: '3px',
                    }
                  : {
                      ...styles.normalText,
                      marginBottom: '6px',
                    }
              }
            >
              {removeUnderscore(partiesFormat[1])}
            </Text>
            <View style={styles.smallColumn}>
              <Text
                style={{
                  ...styles.boldText,
                  marginBottom: '3px',
                }}
              >
                {getLegalCostsSingleValue(
                  legalCostsObj.opposingPartyLegalCosts.firstProceedings
                    .estimatedCosts!,
                )}
              </Text>
              <Text style={styles.normalText}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'recoverableCosts',
                    LegalCostsParty.opposingPartyLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                )}
              </Text>
              <Text style={styles.normalText}>
                {getLegalCostsSingleValue(
                  getLegalCosts(
                    'incurredCosts',
                    LegalCostsParty.opposingPartyLegalCosts,
                    LegalCostsProceedings.firstProceedings,
                    props.currentSnapshot,
                    props.user.settings,
                  ),
                )}
              </Text>
              {hasSecondTrial && (
                <>
                  <Text
                    style={{
                      ...styles.boldText,
                      marginBottom: '3px',
                      marginTop: '20px',
                    }}
                  >
                    {getLegalCostsSingleValue(
                      legalCostsObj.opposingPartyLegalCosts
                        .additionalProceedings!.estimatedCosts!,
                    )}
                  </Text>
                  <Text style={styles.normalText}>
                    {getLegalCostsSingleValue(
                      getLegalCosts(
                        'recoverableCosts',
                        LegalCostsParty.opposingPartyLegalCosts,
                        LegalCostsProceedings.additionalProceedings,
                        props.currentSnapshot,
                        props.user.settings,
                      ),
                    )}
                  </Text>
                  <Text style={styles.normalText}>
                    {getLegalCostsSingleValue(
                      getLegalCosts(
                        'incurredCosts',
                        LegalCostsParty.opposingPartyLegalCosts,
                        LegalCostsProceedings.additionalProceedings,
                        props.currentSnapshot,
                        props.user.settings,
                      ),
                    )}
                  </Text>
                </>
              )}
              {props.forLegalCostsComponent &&
                props.currentSnapshot.hasInsurance && (
                  <>
                    <Text
                      style={{
                        ...styles.boldText,
                        color: 'transparent',
                        marginBottom: '3px',
                        marginTop: '20px',
                      }}
                    >
                      {'No Value'}
                    </Text>
                    <Text
                      style={{ ...styles.normalText, color: 'transparent' }}
                    >
                      {'No Value'}
                    </Text>
                    <Text
                      style={{ ...styles.normalText, color: 'transparent' }}
                    >
                      {'No Value'}
                    </Text>
                    <Text
                      style={{ ...styles.normalText, color: 'transparent' }}
                    >
                      {'No Value'}
                    </Text>
                  </>
                )}
            </View>
          </View>
        </View>
      )}
    </View>
  )
}

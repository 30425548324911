import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from 'victory'
import { roundNumberTo } from '../../../../../../../../services/commonFunctions'
import { getText } from '../../../../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import BarSvg from './BarSvg'
import { useEffect } from 'react'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'

type Props = {
  maxProbability: number
  minAmount: number
  maxAmount: number
  pdfPreview?: boolean
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  widthOfDiv: number
  heightOfDiv: number
  stepSize: number
  reportWhitelabel?: ReportWhitelabel
  graphData: [number, number, number][]
}

export default function GroupGraphComponent(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const calculateTickValues = () => {
    const tickDistance =
      props.graphData[0][0] - props.graphData[props.graphData.length - 1][1]

    var start = props.graphData[props.graphData.length - 1][1]
    var tickArray = [roundNumberTo(start + 0.0001, 2)]
    for (let i = 0; i < 8; i++) {
      start += tickDistance / 9
      let roundingFactor =
        Math.round(props.graphData[0][0]).toString().length - 4

      if (Math.abs(start) > Math.abs(tickDistance / 25)) {
        if (roundingFactor > 0) {
          tickArray.push(
            Math.round(start / 10 ** roundingFactor) * 10 ** roundingFactor,
          )
        } else {
          tickArray.push(Math.round(start))
        }
      }
    }
    tickArray.push(roundNumberTo(props.graphData[0][0] + 0.0001, 2))

    tickArray.push(0)
    return tickArray
  }

  function factor() {
    let arrayOfValues = calculateTickValues()
    let maxLength = 0
    for (let i = 0; i < arrayOfValues.length; i++) {
      if (arrayOfValues[i].toString().length > maxLength) {
        maxLength = arrayOfValues[i].toString().length
      }
    }

    let factor = 80
    if (maxLength > 15) {
      factor = 150
    } else if (maxLength > 14) {
      factor = 120
    } else if (maxLength > 10) {
      factor = 110
    } else if (maxLength > 8) {
      factor = 100
    } else if (maxLength > 4) {
      factor = 90
    }
    return factor
  }

  useEffect(() => {
    const axisLabelElement1 = document.getElementById(
      'chart-axis-2-axisLabel-0',
    )
    const axisLabelElement2 = document.getElementById(
      'chart-axis-0-axisLabel-0',
    )
    if (axisLabelElement1) {
      const tspanElement = axisLabelElement1.getElementsByTagName('tspan')[0] // First tspan

      tspanElement.setAttribute('x', '570')
      tspanElement.setAttribute('y', '190')
    }
    if (axisLabelElement2) {
      const tspanElement = axisLabelElement2.getElementsByTagName('tspan')[0] // First tspan

      tspanElement.setAttribute('y', '40')
    }
  }, [
    // eslint-disable-next-line
    document.getElementById('chart-axis-2-axisLabel-0'),
    // eslint-disable-next-line
    document.getElementById('chart-axis-0-axisLabel-0'),
  ])

  return (
    <>
      <VictoryChart
        domain={{
          y: [
            0,
            props.maxProbability < 0.97
              ? roundNumberTo(props.maxProbability + 0.03, 2)
              : roundNumberTo(props.maxProbability, 2),
          ],
          x: [props.minAmount - 0.1, props.maxAmount + 0.1],
        }}
        /* padding={{
                top: 60,
                left: 120,
                bottom: 120,
                right: 100,
              }} */
        containerComponent={
          props.pdfPreview ? (
            <VictoryContainer />
          ) : (
            <VictoryVoronoiContainer
              //id='distributionGraphLabel'
              data-textattribute="label-65a, label-65b, label-65c, label-65d"
              labels={(item) =>
                `${getText('label-65a', user.settings)} ${
                  item.datum[2] < 0.000002 && item.datum[2] > 0
                    ? getText('label-65b', user.settings)
                    : stringAndRoundBigNumbers(
                        item.datum[2] * 100,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(props.currentSnapshot),
                        false,
                        true,
                      )
                }${getText('label-65c', user.settings)}
                     ${stringAndRoundBigNumbers(
                       item.datum[0],
                       user.settings,
                       resultsSettings.settings.roundedResults,
                       findDisputedAmount(props.currentSnapshot),
                     )}${
                  item.datum[0] !== props.minAmount &&
                  item.datum[0] !== props.maxAmount &&
                  props.resultsFromBackend &&
                  props.resultsFromBackend.model_used === 'statistical'
                    ? '*'
                    : ''
                } ${props.currentSnapshot.currency} ${getText(
                  'label-65d',
                  user.settings,
                )} ${stringAndRoundBigNumbers(
                  item.datum[1],
                  user.settings,
                  resultsSettings.settings.roundedResults,
                  findDisputedAmount(props.currentSnapshot),
                )}${
                  item.datum[1] !== props.minAmount &&
                  item.datum[1] !== props.maxAmount &&
                  props.resultsFromBackend &&
                  props.resultsFromBackend.model_used === 'statistical'
                    ? '*'
                    : ''
                } ${props.currentSnapshot.currency}`
              }
              labelComponent={
                <VictoryTooltip
                  dy={-7}
                  dx={12.5}
                  constrainToVisibleArea
                  style={{
                    fontSize: props.widthOfDiv / factor(),
                    fontFamily: 'poppins-local',
                    fill: '#201a2d',
                    display: (data: { datum?: number[] }) =>
                      data.datum![2] === 0 ? 'none' : 'inline',
                  }}
                  flyoutPadding={{
                    top: 5,
                    bottom: 5,
                    left: 15,
                    right: 15,
                  }}
                  flyoutStyle={{
                    fill: (data) => (data.datum[0] >= 0 ? 'white' : 'white'),
                    stroke: '#201a2d',
                    strokeWidth: 0.3,
                    display: (data) =>
                      data.datum[2] === 0 ? 'none' : 'inline',
                  }}
                />
              }
            />
          )
        }
        width={props.widthOfDiv}
        height={props.heightOfDiv - 33}
      >
        <VictoryAxis
          label="probability (%)"
          data-id={'y-axis'}
          style={{
            axis: { stroke: '#756f6a' },
            grid: { stroke: 'grey', opacity: 0.3 },
            ticks: { stroke: 'grey', size: 2 },
            axisLabel: {
              angle: 0,
              padding: -3,
              fontSize: props.widthOfDiv / factor(),
              fontFamily:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).fontFamily ?? 'poppins-local',

              stroke:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'white',
              strokeWidth: '0.1px',
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'black',
            },
            tickLabels: {
              fontSize: props.widthOfDiv / factor(),
              fontFamily:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).fontFamily ?? 'poppins-local',
              padding: () => {
                const lineElement = document.querySelector(
                  'g[data-id="y-axis"] line[role="presentation"]:first-of-type',
                )
                if (lineElement) {
                  let lineX1 = parseFloat(
                    lineElement.getAttribute('x1') as string,
                  )
                  if (lineX1) {
                    return lineX1 - 40
                  } else return 5
                } else {
                  return 5
                }
              },
              stroke:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'white',
              strokeWidth: '0.1px',
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'black',
            },
          }}
          dependentAxis
          tickFormat={(y: number) => `${Math.round(y * 100)} %`}
        />
        <VictoryBar
          barWidth={({ index }) => {
            let barWidth = 0
            if (props.maxAmount - props.minAmount !== 0) {
              barWidth =
                (props.widthOfDiv *
                  Math.abs(
                    props.graphData[parseInt(index as string)][0] -
                      props.graphData[parseInt(index as string)][1],
                  )) /
                Math.abs(props.maxAmount - props.minAmount)
            }

            return barWidth
          }}
          data={props.graphData}
          y={2}
          x={0}
          style={{
            data: {
              fill: (data) => (data.datum[0] >= 0 ? '#201a2d' : '#ff703e'),
              strokeWidth: 0,
              paddingLeft: 80,
            },
          }}
          dataComponent={
            <BarSvg
              maxAmount={props.maxAmount}
              minAmount={props.minAmount}
              widthOfDiv={props.widthOfDiv}
              sampleData={props.graphData}
              data-openreplay-hidden
            />
          }
        />

        <VictoryAxis
          label={`amount (${props.currentSnapshot.currency})`}
          tickValues={calculateTickValues()}
          tickFormat={(x) =>
            `${stringAndRoundBigNumbers(
              x,
              user.settings,
              resultsSettings.settings.roundedResults,
              findDisputedAmount(props.currentSnapshot),
            )}${
              props.pdfPreview &&
              x !==
                calculateTickValues().sort((a, b) => (a >= b ? 1 : -1))[0] &&
              x !==
                calculateTickValues().sort((a, b) => (a >= b ? -1 : 1))[0] &&
              props.resultsFromBackend &&
              props.resultsFromBackend.model_used === 'statistical'
                ? '*'
                : ''
            }`
          }
          style={{
            axis: { stroke: '#756f6a' },
            ticks: { stroke: 'grey', size: 5 },
            axisLabel: {
              angle: 0,
              fontSize: props.widthOfDiv / factor(),
              fontFamily:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).fontFamily ?? 'poppins-local',

              stroke:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'white',
              strokeWidth: '0.1px',
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'black',
            },
            tickLabels: {
              fontSize: props.widthOfDiv / factor(),
              angle: -45,
              padding: 1,
              textAnchor: 'end',
              fontFamily:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).fontFamily ?? 'poppins-local',
              fontWeight: (data: { [key: string]: any }) =>
                data.tickValue === 0 ? 'bold' : 400,
              fill:
                getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                ).color ?? 'black',
            },
          }}
        />
      </VictoryChart>
    </>
  )
}

import { formattedNumToString } from '../../../../../../../services/formatNum'
import {
  TreeAnalysisResults,
  TreeTableInfo,
} from '../../../../../../../models/generalTypes'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../../states/UserState'
import { WhiteTooltip } from '../../../../../../../Components/tooltip/TooltipComponent'
import {
  roundTo2Decimals,
  stringAndRoundTo2Decimals,
} from '../../../../../../../Modules/DisputeModules/AppFunctions'
import valueOfTreeImg from '../../../../../../../resources/images/229-value_of_case_small.svg'
import arrowUpImg from '../../../../../../../resources/images/223-arrow_up.svg'
import arrowDownImg from '../../../../../../../resources/images/222-arrow_down.svg'
import { useEffect, useState } from 'react'
import {
  textForValueOfTreePercentageChange,
  treeHasInterestUsingSnapshot,
} from '../../../../../../../services/treeFunctions/treeBasicFunctions'
import {
  InterestViewOption,
  TypeOfInstance,
} from '../../../../../../../models/enums'
import { getText } from '../../../../../../../services/textFunctions'

type Props = {
  treeIndex: number
  currentTreeTableInfo: TreeTableInfo | undefined
  calculationOfTreeArray: TreeAnalysisResults[] | undefined
}

export default function ValueOfTree(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [valueOfTreePercentageChange, setValueOfTreePrecentageChange] =
    useState<number | undefined>(undefined)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  useEffect(() => {
    let tempValueOfTreePrecentageChange = undefined
    if (
      props.currentTreeTableInfo?.currentValueOfTree !== undefined &&
      props.currentTreeTableInfo?.previousValueOfTree !== undefined
    ) {
      let interestViewOption = props.currentTreeTableInfo.treeInterestViewOption
      if (
        props.currentTreeTableInfo?.previousValueOfTree[interestViewOption] !==
          0 &&
        props.currentTreeTableInfo?.previousValueOfTree[interestViewOption] !==
          undefined
      ) {
        tempValueOfTreePrecentageChange =
          ((props.currentTreeTableInfo?.currentValueOfTree[
            interestViewOption
          ]! -
            props.currentTreeTableInfo?.previousValueOfTree[
              interestViewOption
            ]!) /
            Math.abs(
              props.currentTreeTableInfo?.previousValueOfTree[
                interestViewOption
              ]!,
            )) *
          100
      }
    }

    setValueOfTreePrecentageChange(tempValueOfTreePrecentageChange)
  }, [props.currentTreeTableInfo, props.treeIndex])

  return (
    <>
      {props.currentTreeTableInfo &&
        props.currentTreeTableInfo.currentValueOfTree &&
        props.currentTreeTableInfo.currentValueOfTree[
          props.currentTreeTableInfo.treeInterestViewOption
        ] !== undefined && (
          <div
            className="valueOfTreeAndRecentChangeContainer"
            data-openreplay-obscured
          >
            <WhiteTooltip
              textAttribute={[
                'tooltip-41a',
                'tooltip-41b',
                'tooltip-42a',
                'tooltip-42b',
                'tooltip-42c',
                'tooltip-42d',
              ]}
              id={`valueOfTreeTooltip-${props.treeIndex}`}
              title={
                <p data-openreplay-obscured>
                  {getText('tooltip-41a', user.settings)}{' '}
                  {formattedNumToString(
                    roundTo2Decimals(
                      props.currentTreeTableInfo.currentValueOfTree[
                        props.currentTreeTableInfo.treeInterestViewOption
                      ],
                    ),
                    user.settings,
                  )}{' '}
                  {scenarioSnapshot.currentSnapshot.currency}{' '}
                  {getText('tooltip-41b', user.settings, partiesFormat)}{' '}
                  {scenarioSnapshot.currentSnapshot.firstTrialDate !==
                    undefined &&
                  treeHasInterestUsingSnapshot(
                    scenarioSnapshot.currentSnapshot,
                    props.treeIndex,
                  )
                    ? props.currentTreeTableInfo.treeInterestViewOption ===
                      InterestViewOption.noInterest
                      ? getText('tooltip-64', user.settings, partiesFormat)
                      : `${getText('tooltip-42a', user.settings)} ${
                          props.currentTreeTableInfo.treeInterestViewOption ===
                          InterestViewOption.interest1st
                            ? getText('tooltip-42b', user.settings)
                            : scenarioSnapshot.currentSnapshot.court ===
                              TypeOfInstance.Public_Court
                            ? getText('tooltip-42c', user.settings)
                            : getText('tooltip-42d', user.settings)
                        }.`
                    : ''}
                </p>
              }
              placement="right-start"
              data-openreplay-obscured
            >
              <div className="valueOfTreeContainer" data-openreplay-obscured>
                <img
                  src={valueOfTreeImg}
                  alt="valueOfTreeImg"
                  className="valueOfTreeImg"
                />
                <p
                  className="valueOfTreeText"
                  id={`valueOfTreeText-${props.treeIndex}`}
                >
                  {formattedNumToString(
                    roundTo2Decimals(
                      props.currentTreeTableInfo.currentValueOfTree[
                        props.currentTreeTableInfo.treeInterestViewOption
                      ],
                    ),
                    user.settings,
                  )}{' '}
                  {scenarioSnapshot.currentSnapshot.currency}
                </p>
              </div>
            </WhiteTooltip>
            {props.currentTreeTableInfo &&
              props.currentTreeTableInfo.currentValueOfTree &&
              props.currentTreeTableInfo.previousValueOfTree &&
              valueOfTreePercentageChange !== undefined &&
              valueOfTreePercentageChange !== 0 && (
                <WhiteTooltip
                  data-openreplay-obscured
                  textAttribute={[
                    'tooltip-43a',
                    'tooltip-43b',
                    'tooltip-43c',
                    'tooltip-43d',
                    'tooltip-43e',
                  ]}
                  id={`valueOfTreePrecentageChange-${props.treeIndex}`}
                  title={
                    <p data-openreplay-obscured>
                      {getText('tooltip-43a', user.settings)}{' '}
                      {valueOfTreePercentageChange < 0
                        ? getText('tooltip-43b', user.settings)
                        : getText('tooltip-43c', user.settings)}{' '}
                      {textForValueOfTreePercentageChange(
                        valueOfTreePercentageChange,
                        user.settings,
                      )}
                      % {getText('tooltip-43d', user.settings)}{' '}
                      {stringAndRoundTo2Decimals(
                        Math.abs(
                          props.currentTreeTableInfo.currentValueOfTree![
                            props.currentTreeTableInfo.treeInterestViewOption
                          ]! -
                            props.currentTreeTableInfo.previousValueOfTree![
                              props.currentTreeTableInfo.treeInterestViewOption
                            ]!,
                        ),
                        user.settings,
                      )}{' '}
                      {scenarioSnapshot.currentSnapshot.currency}{' '}
                      {getText('tooltip-43e', user.settings)}
                    </p>
                  }
                  placement="right-start"
                >
                  <div
                    className={'valueOfTreePrecentageChange'}
                    data-openreplay-obscured
                  >
                    <img
                      src={
                        valueOfTreePercentageChange < 0
                          ? arrowDownImg
                          : arrowUpImg
                      }
                      alt="changeOfvalueOfTreeImg"
                      className="changeOfvalueOfTreeImg"
                    />
                    <p
                      className={`${
                        valueOfTreePercentageChange < 0
                          ? 'valueOfTreePercentageChangeText negative'
                          : 'valueOfTreePercentageChangeText'
                      }`}
                    >
                      {textForValueOfTreePercentageChange(
                        valueOfTreePercentageChange,
                        user.settings,
                      )}
                      {' %'}
                    </p>
                  </div>
                </WhiteTooltip>
              )}
          </div>
        )}
    </>
  )
}

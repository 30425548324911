import React, { useRef, useState } from 'react'

import deleteImg from '../../../../../../resources/images/029-deleteBucketSign.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { IndependentClaim } from '../../../../../../models/independentClaim'
import { SnapshotSelectorObject } from '../../../../../../models/generalTypes'
import { AccessRole, UndoRedoType } from '../../../../../../models/enums'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../../services/changeGlobalSnapshot'
import HoverComponent from '../../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import InputText from '../../../../../../Components/Inputs/InputText/InputText'
import InputNum from '../../../../../../Components/Inputs/InputNum/InputNum'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'
import { freemiumState } from '../../../../../../states/FreemiumState'

type Props = {
  claimIndex: number
  requirementIndex: number
  errors: string[]
  ownRole: AccessRole
}

export default function SingleRequirement(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )

  const currentLiabilityRequirement = (
    scenarioSnapshot.currentSnapshot.claims[
      props.claimIndex
    ] as IndependentClaim
  ).liabilityRequirements[props.requirementIndex]

  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature

  const user = useRecoilValue(userState)

  function handleChangeRequirementName(e: any) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: e.target.id,
      undoRedoType: UndoRedoType.input,
      value: e.target.value,
      key: 'claims',
      claimIndex: props.claimIndex,
      secondaryIndex: props.requirementIndex,
      key2: 'liabilityRequirements',
      key3: 'title',
    }
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleChangeRequirementProbability(e: any) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: e.target.id,
      undoRedoType: UndoRedoType.input,
      value: e.target.value ?? 0,
      key: 'claims',
      claimIndex: props.claimIndex,
      secondaryIndex: props.requirementIndex,
      key2: 'liabilityRequirements',
      key3: 'probability',
    }
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleRemoveRequirement(e: any) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: [`addLiabilityRequirement-${props.claimIndex}`, e.target.id],
      undoRedoType: UndoRedoType.button,
      value: 'remove',
      key: 'claims',
      claimIndex: props.claimIndex,
      secondaryIndex: props.requirementIndex,
      key2: 'liabilityRequirements',
    }
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    //Mixpanel 40 (All)
    logActivity(freemium.isFreemium, 'Removed liability requirement')
  }

  return (
    <div className="requirement" key={props.requirementIndex}>
      <InputText
        label={getText('label-16', user.settings)}
        labelTextAttribute="label-16"
        className="liabilityMultiLine"
        width={297}
        value={currentLiabilityRequirement.title}
        id={`requirementText-${props.claimIndex}_${props.requirementIndex}`}
        tabIndex={5}
        name={`requirementText-${props.claimIndex}_${props.requirementIndex}`}
        onChange={(e: any) => handleChangeRequirementName(e)}
        error={props.errors.includes(
          `requirementText-${props.claimIndex}_${props.requirementIndex}`,
        )}
        errorMessage={getText('error-35', user.settings)}
        errorTextAttribute="error-35"
        maxLength={74}
        disabled={props.ownRole === AccessRole.VIEWER}
        multiline
      />

      <InputNum
        label={getText('label-17', user.settings)}
        labelTextAttribute="label-17"
        defaultZero
        suffix="%"
        value={currentLiabilityRequirement.probability}
        maxValue={100}
        maxNumberOfDecimals={0}
        tabIndex={6}
        name={`requirementLikelihood-${props.claimIndex}_${props.requirementIndex}`}
        onChange={(e: any) => handleChangeRequirementProbability(e)}
        id={`requirementLikelihood-${props.claimIndex}_${props.requirementIndex}`}
        width={95}
        error={props.errors.includes(
          `requirementLikelihood-${props.claimIndex}_${props.requirementIndex}`,
        )}
        errorMessage={getText('error-19', user.settings)}
        errorTextAttribute={'error-19'}
        readOnly={props.ownRole === AccessRole.VIEWER}
        disabled={props.ownRole === AccessRole.VIEWER}
      />

      <div className="deleteContainer">
        {hoverElement ===
        `deleteRequirement-${props.claimIndex}_${props.requirementIndex}` ? (
          <div
            className="hoverComponentContainer"
            style={{ top: '-10px', left: '30px' }}
          >
            <HoverComponent
              hoverText={getText('hover-16', user.settings)}
              textAttribute="hover-16"
              id={`deleteRequirement-${props.claimIndex}_${props.requirementIndex}`}
              style={{
                width: '160px',
                padding: '4px 20px',
              }}
            />
          </div>
        ) : null}
        {props.ownRole !== AccessRole.VIEWER && (
          <img
            src={deleteImg}
            alt="deleteImg"
            className="deleteImg"
            id={`deleteRequirementImg-${props.claimIndex}_${props.requirementIndex}`}
            onClick={(e: any) => {
              handleRemoveRequirement(e)
              secondaryHover.current = 'close'
              setHoverElement('')
            }}
            onMouseEnter={() => {
              secondaryHover.current = `deleteRequirement-${props.claimIndex}_${props.requirementIndex}`
              setTimeout(() => {
                if (
                  secondaryHover.current ===
                  `deleteRequirement-${props.claimIndex}_${props.requirementIndex}`
                ) {
                  setHoverElement(
                    `deleteRequirement-${props.claimIndex}_${props.requirementIndex}`,
                  )
                }
              }, 1000)
            }}
            onMouseLeave={() => {
              secondaryHover.current = 'close'
              setHoverElement('')
            }}
          />
        )}
      </div>
    </div>
  )
}

import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { getText } from '../../../../../../../../services/textFunctions'
import { User } from '../../../../../../../../models/user'
import { formattedNumToString } from '../../../../../../../../services/formatNum'
import {
  calculateWeightedValues,
  findClaimedAmountOfClaim,
  findCounterClaimedAmountOfClaim,
  findWeightedValueOfClaim,
  roundNumberTo,
} from '../../../../../../../../services/commonFunctions'
import { TreeClaim } from '../../../../../../../../models/treeModels/treeClaim'
import { IndependentClaim } from '../../../../../../../../models/independentClaim'
import {
  getClaimWeightedValue,
  getClientAwardedAmountSum,
  getOpposingPartyAwardedAmountSum,
  hasClaimAmount,
  hasCounterClaimAmount,
} from '../../../../../../../../services/claimFunctions'
import {
  claimsOverviewFontSize,
  getStyleFromWhitelabel,
} from '../../../../../../../../services/reportFunctions'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { InterestViewOption } from '../../../../../../../../models/enums'
import { DateTime } from 'luxon'
import { atLeastOneClaimHasInterest } from '../../../../../../../../services/resultsFunctions'

type Props = {
  colors: { [key: string]: string }
  currentSnapshot: ScenarioSnapshot
  user: User
  reportWhitelabel?: ReportWhitelabel
}

export default function ClaimsOverviewComponentDocument(props: Props) {
  const styles = StyleSheet.create({
    claimsOverviewContainer: { marginTop: '10px' },

    listTitlesContainer: {
      display: 'flex',
      flexDirection: 'row',
    },
    listTitleDiv: {
      width: '225px',
      paddingLeft: '3px',
    },
    listTitleText: {
      fontSize: '8px',
      textAlign: 'left',
    },
    listClientDiv: {
      width: '90px',
    },
    listOpposingPartyDiv: {
      width: '90px',
    },
    listWeigthedDiv: {
      width: '90px',
    },
    listTitleRightText: {
      fontSize: '8px',
      textAlign: 'right',
    },
    listRow: {
      minHeight: '17px',
      width: '500px',
      borderRadius: '3px',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
    },
    listPreviewNameContainer: {
      width: '230px',
      padding: '3px',
    },
    listPreviewName: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).marginTop,
    },
    listPreviewInfoContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    listPreviewValueContainer: {
      width: '90px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    client: {
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      marginRight: '8px',
    },
    opposing: {
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      marginRight: '8px',
    },

    listPreviewValueText: {
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      marginRight: '8px',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).marginTop,
    },
    listSumContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    sumTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      width: '230px',
    },
    sumTitleText: {
      fontSize: '8px',
    },
    sumValueContainer: {
      width: '90px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    sumValueText: {
      fontSize: '8px',
    },

    weigthedValueInfoMessage: {
      fontSize: '6px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).marginTop,
    },
  })

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  function formattedClaimAmount(
    claim: IndependentClaim | TreeClaim,
    index: number,
  ) {
    return hasClaimAmount(claim)
      ? formattedNumToString(
          Math.abs(
            roundNumberTo(
              findClaimedAmountOfClaim(props.currentSnapshot, index),
              2,
            ),
          ),
          props.user.settings,
        ) +
          ' ' +
          props.currentSnapshot.currency
      : '-'
  }

  function formattedCounterClaimAmount(
    claim: IndependentClaim | TreeClaim,
    index: number,
  ) {
    return hasCounterClaimAmount(claim)
      ? formattedNumToString(
          Math.abs(
            roundNumberTo(
              findCounterClaimedAmountOfClaim(props.currentSnapshot, index),
              2,
            ),
          ),
          props.user.settings,
        ) +
          ' ' +
          props.currentSnapshot.currency
      : '-'
  }
  const valueOfCase = calculateWeightedValues(props.currentSnapshot)

  function formattedWeightedValueOfClaim(index: number) {
    return (
      formattedNumToString(
        roundNumberTo(
          findWeightedValueOfClaim(props.currentSnapshot, index),
          2,
        ),
        props.user.settings,
      ) +
      ' ' +
      props.currentSnapshot.currency
    )
  }

  return (
    <View style={styles.claimsOverviewContainer}>
      <View style={styles.listTitlesContainer}>
        <View style={styles.listTitleDiv}>
          <Text style={styles.listTitleText}>
            {getText('title-222', props.user.settings)}
          </Text>
        </View>
        <View style={styles.listClientDiv}>
          <Text style={styles.listTitleRightText}>
            {getText('title-223', props.user.settings, partiesFormat).length >
            17
              ? getText('title-223', props.user.settings, partiesFormat)
                  .substring(0, 16)
                  .trim() + '...'
              : getText('title-223', props.user.settings, partiesFormat)}
          </Text>
        </View>
        <View style={styles.listOpposingPartyDiv}>
          <Text style={styles.listTitleRightText}>
            {getText('title-224', props.user.settings, partiesFormat).length >
            17
              ? getText('title-224', props.user.settings, partiesFormat)
                  .substring(0, 16)
                  .trim() + '...'
              : getText('title-224', props.user.settings, partiesFormat)}
          </Text>
        </View>
        <View style={styles.listWeigthedDiv}>
          <Text style={styles.listTitleRightText}>
            {getText('title-225', props.user.settings)}
          </Text>
        </View>
      </View>
      {props.currentSnapshot.claims.map((claim, index) => (
        <View
          key={`claimList-${index}`}
          style={
            index % 2 === 0
              ? {
                  ...styles.listRow,
                  backgroundColor: props.colors.thursdayGray,
                }
              : styles.listRow
          }
          wrap={false}
        >
          <View style={styles.listPreviewNameContainer}>
            <Text style={styles.listPreviewName}>{claim.name}</Text>
          </View>
          <View style={styles.listPreviewInfoContainer}>
            <View style={styles.listPreviewValueContainer}>
              <Text
                style={
                  hasClaimAmount(claim)
                    ? {
                        ...styles.client,
                        ...styles.listPreviewValueText,
                        ...claimsOverviewFontSize(
                          formattedClaimAmount(claim, index),
                        ),
                      }
                    : {
                        ...claimsOverviewFontSize(
                          formattedClaimAmount(claim, index),
                        ),
                        ...styles.listPreviewValueText,
                      }
                }
              >
                {formattedClaimAmount(claim, index)}
              </Text>
            </View>
            <View style={styles.listPreviewValueContainer}>
              <Text
                style={
                  hasCounterClaimAmount(claim)
                    ? {
                        ...claimsOverviewFontSize(
                          formattedCounterClaimAmount(claim, index),
                        ),
                        ...styles.opposing,
                        ...styles.listPreviewValueText,
                      }
                    : {
                        ...claimsOverviewFontSize(
                          formattedCounterClaimAmount(claim, index),
                        ),
                        ...styles.listPreviewValueText,
                      }
                }
              >
                {formattedCounterClaimAmount(claim, index)}
              </Text>
            </View>
            <View style={styles.listPreviewValueContainer}>
              <Text
                style={
                  getClaimWeightedValue(claim) >= 0
                    ? {
                        ...claimsOverviewFontSize(
                          formattedCounterClaimAmount(claim, index),
                        ),
                        ...styles.client,
                        ...styles.listPreviewValueText,
                      }
                    : {
                        ...claimsOverviewFontSize(
                          formattedCounterClaimAmount(claim, index),
                        ),
                        ...styles.opposing,
                        ...styles.listPreviewValueText,
                      }
                }
              >
                {formattedWeightedValueOfClaim(index)}
              </Text>
            </View>
          </View>
        </View>
      ))}
      <View style={styles.listSumContainer}>
        <View style={styles.sumTitleContainer}>
          <Text style={{ ...styles.sumTitleText }}>
            {getText('title-226', props.user.settings)}
          </Text>
        </View>
        <View style={styles.sumValueContainer}>
          <Text
            style={{
              ...claimsOverviewFontSize(
                getClientAwardedAmountSum(
                  props.currentSnapshot,
                  props.user.settings,
                ),
              ),
              ...styles.client,
            }}
          >
            {getClientAwardedAmountSum(
              props.currentSnapshot,
              props.user.settings,
            )}
          </Text>
        </View>
        <View style={styles.sumValueContainer}>
          <Text
            style={{
              ...claimsOverviewFontSize(
                getOpposingPartyAwardedAmountSum(
                  props.currentSnapshot,
                  props.user.settings,
                ),
              ),
              ...styles.opposing,
            }}
          >
            {getOpposingPartyAwardedAmountSum(
              props.currentSnapshot,
              props.user.settings,
            )}
          </Text>
        </View>
        <View style={styles.sumValueContainer}>
          <Text
            style={
              valueOfCase > 0
                ? {
                    ...claimsOverviewFontSize(
                      formattedNumToString(
                        roundNumberTo(valueOfCase, 2),
                        props.user.settings,
                      ) +
                        ' ' +
                        props.currentSnapshot.currency,
                    ),
                    ...styles.client,
                  }
                : {
                    ...claimsOverviewFontSize(
                      formattedNumToString(
                        roundNumberTo(valueOfCase, 2),
                        props.user.settings,
                      ) +
                        ' ' +
                        props.currentSnapshot.currency,
                    ),
                    ...styles.opposing,
                  }
            }
          >
            {formattedNumToString(
              roundNumberTo(valueOfCase, 2),
              props.user.settings,
            ) +
              ' ' +
              props.currentSnapshot.currency}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 18 }}>
        {props.currentSnapshot.interestViewOption !==
        InterestViewOption.noInterest ? (
          <Text style={styles.weigthedValueInfoMessage}>
            {getText('description-233', props.user.settings)}{' '}
            {DateTime.fromISO(
              (props.currentSnapshot.interestViewOption ===
              InterestViewOption.interest1st
                ? props.currentSnapshot.firstTrialDate
                : props.currentSnapshot.secondTrialDate) as string,
            ).toFormat('dd LLL yyyy')}
            .
          </Text>
        ) : atLeastOneClaimHasInterest(props.currentSnapshot) ? (
          <Text style={styles.weigthedValueInfoMessage}>
            {getText('description-234', props.user.settings)}.
          </Text>
        ) : null}
        {/* data-textattribute="description-188" */}
        <Text style={styles.weigthedValueInfoMessage}>
          {getText('description-188', props.user.settings)}
        </Text>
      </View>
    </View>
  )
}

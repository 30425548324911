import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { userState } from '../../../../../../../../states/UserState'
import { calculateGetPayArray } from '../../../../../../../../services/resultsFunctions'
import {
  GREEK_EASTER,
  INFANTRY_WEST,
} from '../../../../../../../../services/constants'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { getText } from '../../../../../../../../services/textFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
}
export default function GetPayGraph(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const getPayGraphArray = calculateGetPayArray(props.resultsFromBackend)

  return (
    <>
      {!props.pdfPreview && (
        <div className="getPayGraphTitle" data-textattribute="title-263">
          {getText('title-263', user.settings)}
        </div>
      )}
      <div
        className="getPayGraphSubtitle"
        data-textattribute="description-249"
        style={getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.regular,
          false,
          false,
        )}
      >
        {getText('description-249', user.settings)}
      </div>

      <div className={'getPayChart'} data-openreplay-obscured>
        <div className="payGetGraphContainer" id="getPayGraphContainer">
          {getPayGraphArray.map((getPayGraphRow, index) => (
            <div
              className="getPayGraphRow"
              id={`getPayGraphRow-${index}`}
              key={`getPayGraphRow-${index}`}
            >
              <div
                className="getPayGraphRowLeftPart"
                id={`getPayGraphRowLeftPart-${index}`}
                data-textattribute="description-250, description-251, description-252, description-253"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  false,
                  false,
                )}
              >
                {getPayGraphRow[0] > 0
                  ? `${getText(
                      'description-250',
                      user.settings,
                    )} ${stringAndRoundBigNumbers(
                      getPayGraphRow[0],
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    )}${
                      props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''
                    } ${props.currentSnapshot.currency}`
                  : getPayGraphRow[0] === 0
                  ? `${getText('description-251', user.settings)} 0${
                      props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''
                    } ${props.currentSnapshot.currency} ${getText(
                      'description-252',
                      user.settings,
                    )}`
                  : getPayGraphRow[0] === -0.00001234567
                  ? `${getText('description-253', user.settings)} 0${
                      props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''
                    } ${props.currentSnapshot.currency}`
                  : `${getText(
                      'description-253',
                      user.settings,
                    )} ${stringAndRoundBigNumbers(
                      Math.abs(getPayGraphRow[0]),
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    )}${
                      props.resultsFromBackend &&
                      props.resultsFromBackend.model_used === 'statistical'
                        ? '*'
                        : ''
                    } ${props.currentSnapshot.currency}`}{' '}
              </div>
              <div className="getPayGraphRowRightPart">
                <div
                  className="getPayBar"
                  id={`getPayBar-${index}`}
                  style={{
                    width: getPayGraphRow[1] * 330,
                    backgroundColor:
                      getPayGraphRow[0] >= 0 ? INFANTRY_WEST : GREEK_EASTER,
                  }}
                ></div>
                <div
                  className="getPayBarPercentage"
                  id={`getPayBarPercentage-${index}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    false,
                    false,
                  )}
                >
                  {stringAndRoundBigNumbers(
                    getPayGraphRow[1] * 100,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                    false,
                    true,
                  )}
                  %
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import Button from '../../../../../../../../Components/Buttons/Button/Button'
//import savePurpleImg from '../../../../../../../../resources/images/109-saveIconPurple.svg'
import editButtonImg from '../../../../../../../../resources/images/027-renameSign.svg'
import undoBlue from '../../../../../../../../resources/images/111-undo.svg'
import undoGray from '../../../../../../../../resources/images/113-noUndo.svg'
import redoBlue from '../../../../../../../../resources/images/110-redo.svg'
import redoGray from '../../../../../../../../resources/images/112-noRedo.svg'
import deleteImg from '../../../../../../../../resources/images/180-eraseDescription.svg'
//import resetImg from '../../../../../../../../resources/images/181-backToDefault.svg'
import {
  deepCloneObject,
  isMac,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { reportState } from '../../../../../../../../states/ReportState'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { pageDepthState } from '../../../../../../../../states/PageDepthState'
import {
  getText,
  reportSectionType,
} from '../../../../../../../../services/textFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
  SectionTitleKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { userState } from '../../../../../../../../states/UserState'
import { allowShortcutsState } from '../../../../../../../../states/AllowShortcutsState'
import { claimPreviewIdArrayFunction____new } from '../../../../../../../../services/claimFunctions'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  textFromSnapshot: string
  sectionIndex: number
  id: string
  placeholder: string
  defaultText: string
  sectionTitle: SectionTitleKeys
  claimIndex?: number
  treeParagraph?: boolean
  visible: boolean
  flexDirectionOfParagraph?: string[]
  setFlexDirectionOfParagraph?: (flexDirection: string[]) => void
  reportWhitelabel: ReportWhitelabel | undefined
}

export default function ReportParagraph(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const [editMode, setEditMode] = useState(false)
  const [showEditButton, setShowEditButton] = useState(false)
  const [isEmpty, setIsEmpty] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [editableText, setEditableText] = useState(props.textFromSnapshot)
  const [isUndoAvailable, setIsUndoAvailable] = useState(false) // a boolean that defines if the undo button is enabled -- bool
  const [isRedoAvailable, setIsRedoAvailable] = useState(false) // a boolean that defines if the redo button is enabled -- bool
  const [doingRedoUndo, setDoingRedoUndo] = useState(false)
  const [undoRedoIndex, setUndoRedoIndex] = useState(0)
  const [undoRedoState, setundoRedoState] = useState<string[]>([])
  const [report, setReport] = useRecoilState(reportState)
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot
  const pageDepth = useRecoilValue(pageDepthState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  function handleTextareaInput(): void {
    const textarea = document.querySelector(
      `#reportParagraphTextArea-description-${props.id}`,
    ) as HTMLTextAreaElement
    if (textarea) {
      updateTextareaHeight(textarea)
    }
  }

  useEffect(() => {
    if (props.setFlexDirectionOfParagraph && props.claimIndex !== undefined) {
      let tempFlexDirectionOfParagraph = deepCloneObject(
        props.flexDirectionOfParagraph,
      )
      if (editMode || editableText.length > 0) {
        tempFlexDirectionOfParagraph[props.claimIndex] = 'row'
      } else {
        tempFlexDirectionOfParagraph[props.claimIndex] = 'column'
      }
      props.setFlexDirectionOfParagraph(tempFlexDirectionOfParagraph)
    }
    // eslint-disable-next-line
  }, [editMode, editableText])

  useEffect(() => {
    const textarea = document.querySelector(
      `#reportParagraphTextArea-description-${props.id}`,
    ) as HTMLTextAreaElement
    if (textarea) {
      updateTextareaHeight(textarea)
      textarea.addEventListener('input', handleTextareaInput)
    }

    return () => {
      if (textarea) {
        textarea.removeEventListener('input', handleTextareaInput)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode])

  function updateTextareaHeight(textarea: HTMLTextAreaElement): void {
    const height = calculateTextareaHeight(textarea)
    textarea.style.height = `${height}px`
  }

  function calculateTextareaHeight(textarea: HTMLTextAreaElement): number {
    // Clone the textarea and set its value to the current value of the original textarea
    const clone: HTMLTextAreaElement =
      textarea.cloneNode() as HTMLTextAreaElement
    clone.value = textarea.value
    clone.style.width = textarea.offsetWidth + 'px'
    clone.style.height = 'auto'
    clone.style.position = 'absolute'
    clone.style.top = '-9999px'
    clone.style.left = '-9999px'
    // Add the cloned textarea to the document
    document.body.appendChild(clone)
    // Get the height of the cloned textarea
    const height: number = clone.scrollHeight + 30
    // Remove the cloned textarea from the document
    document.body.removeChild(clone)
    // Return the height
    return height
  }

  useEffect(() => {
    if (editMode) {
      //Mixpanel 156 (All)
      logActivity(
        freemium.isFreemium,
        'Edited a section description in the report',
        {
          section: props.claimIndex
            ? props.treeParagraph
              ? 'tree'
              : 'claim'
            : props.sectionTitle,
        },
      )
      setTimeout(() => {
        if (
          document.getElementById(
            `reportParagraphTextArea-description-${props.id}`,
          )
        ) {
          document
            .getElementById(`reportParagraphTextArea-description-${props.id}`)
            ?.focus()
        }
      }, 40)
    }
    // eslint-disable-next-line
  }, [editMode])
  useEffect(() => {
    setIsEmpty(editableText.length === 0)
  }, [editableText])

  useEffect(() => {
    window.addEventListener('mousedown', mouseDownLeaveEditMode)

    return () => {
      window.removeEventListener('mousedown', mouseDownLeaveEditMode)
    }
    // eslint-disable-next-line
  }, [editableText, editMode])

  function mouseDownLeaveEditMode(e: any) {
    if (editMode && !e.target.id.includes(`-description-${props.id}`)) {
      setEditMode(false)
    }
  }

  useEffect(() => {
    if (!doingRedoUndo) {
      let handle = setTimeout(() => {
        let tempUndoRedoState = [...undoRedoState]
        let tempUndoRedoIndex = undoRedoIndex
        if (tempUndoRedoIndex < tempUndoRedoState.length - 1) {
          tempUndoRedoState.splice(tempUndoRedoIndex + 1)
        }

        tempUndoRedoState.push(editableText)

        if (tempUndoRedoIndex >= 200) {
          tempUndoRedoState.shift()
          tempUndoRedoIndex = 199
        }
        if (firstLoad) {
          setundoRedoState(tempUndoRedoState)
          setFirstLoad(false)
        } else {
          setundoRedoState(tempUndoRedoState)
          setUndoRedoIndex(tempUndoRedoIndex + 1)
          updateReportState(editableText)
        }
        if (tempUndoRedoIndex > 0) {
          setIsUndoAvailable(true)
        }
      }, 300)

      return () => clearTimeout(handle)
    } else {
      updateReportState(editableText)
    }

    // eslint-disable-next-line
  }, [editableText])

  useEffect(() => {
    if (undoRedoIndex <= undoRedoState.length - 1) {
      if (undoRedoIndex === 0) {
        setIsUndoAvailable(false)
      } else {
        setIsUndoAvailable(true)
      }

      if (undoRedoIndex === undoRedoState.length - 1) {
        setIsRedoAvailable(false)
      } else {
        setIsRedoAvailable(true)
      }
    }

    document.addEventListener('keydown', onKeyPress)

    return () => {
      document.removeEventListener('keydown', onKeyPress)
    }
    // eslint-disable-next-line
  }, [undoRedoIndex, doingRedoUndo, editableText, allowShortcuts])

  const onKeyPress = (e: KeyboardEvent) => {
    if (pageDepth === 3.1 && editMode && allowShortcuts) {
      if (
        (e.code === 'KeyZ' && e.ctrlKey && !e.shiftKey && !isMac()) ||
        (e.code === 'KeyZ' && e.metaKey && !e.shiftKey && isMac())
      ) {
        if (!doingRedoUndo) {
          onClickUndo()
        }
      } else if (
        (e.code === 'KeyZ' && e.ctrlKey && e.shiftKey && !isMac()) ||
        (e.code === 'KeyZ' && e.metaKey && e.shiftKey && isMac())
      ) {
        if (!doingRedoUndo) {
          onClickRedo()
        }
      }
    }
  }

  function onClickUndo() {
    var nextUndoArrayIndex = undoRedoIndex - 1
    if (nextUndoArrayIndex < 0) {
      nextUndoArrayIndex = 0
    }
    setDoingRedoUndo(true)

    setUndoRedoIndex(nextUndoArrayIndex)
    setEditableText(undoRedoState[nextUndoArrayIndex])
    setTimeout(() => {
      setDoingRedoUndo(false)
    }, 600)
    setTimeout(() => {
      handleTextareaInput()
    }, 100)
  }
  function onClickRedo() {
    var nextUndoArrayIndex = undoRedoIndex + 1
    if (nextUndoArrayIndex > undoRedoState.length - 1) {
      nextUndoArrayIndex = undoRedoState.length - 1
    }
    setDoingRedoUndo(true)

    setUndoRedoIndex(nextUndoArrayIndex)
    setEditableText(undoRedoState[nextUndoArrayIndex])
    setTimeout(() => {
      setDoingRedoUndo(false)
    }, 50)

    setTimeout(() => {
      handleTextareaInput()
    }, 100)
  }

  function updateReportState(tempEditableText: string) {
    let tempReportData = deepCloneObject(report.reportData)

    if (props.claimIndex !== undefined) {
      tempReportData.sections[props.sectionIndex].contents.claims[
        props.claimIndex
      ].description = tempEditableText
    } else {
      tempReportData.sections[props.sectionIndex].contents.description =
        tempEditableText
    }
    setReport({
      ...report,
      reportData: tempReportData,
    })
  }

  function deleteText() {
    setEditableText('')
    setTimeout(() => {
      handleTextareaInput()
    }, 100)
  }

  return (
    <>
      <div
        className="reportParagraphContainer"
        onMouseEnter={
          editMode || isEmpty ? undefined : () => setShowEditButton(true)
        }
        onMouseLeave={() => setShowEditButton(false)}
        style={
          props.claimIndex !== undefined &&
          !props.treeParagraph &&
          props.flexDirectionOfParagraph !== undefined
            ? { paddingLeft: 0 }
            : undefined
        }
      >
        <div
          className={
            props.claimIndex !== undefined && !props.treeParagraph
              ? 'textarea-size claimParagraph'
              : 'textarea-size'
          }
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
          )}
          id={`textarea-size-description-${props.id}`}
        >
          {editableText}
        </div>
        {showEditButton && !editMode && props.visible ? (
          <div
            className={
              props.claimIndex !== undefined && !props.treeParagraph
                ? 'editButtonContainer claims'
                : 'editButtonContainer'
            }
            onClick={() => {
              setEditMode(true)
              setShowEditButton(false)
            }}
          >
            <img src={editButtonImg} alt="" className="editButtonImg" />
            <p
              className="editButtonText"
              id={`pdfPreviewEditButtonText-${props.id}`}
              data-textattribute="button-88"
            >
              {getText('button-88', user.settings)}
            </p>
          </div>
        ) : null}
        {isEmpty && !editMode ? (
          <div
            className="buttonContainer"
            style={
              props.claimIndex !== undefined &&
              props.flexDirectionOfParagraph !== undefined &&
              props.flexDirectionOfParagraph[props.claimIndex] === 'column'
                ? { zoom: 0.89 }
                : undefined
            }
          >
            <Button
              buttonText={`${getText('button-43a', user.settings)} ${
                props.claimIndex !== undefined
                  ? claimPreviewIdArrayFunction____new(
                      currentSnapshot,
                      user.settings,
                    )[props.claimIndex]
                  : reportSectionType(user.settings)[props.sectionTitle]
              } ${getText('button-43b', user.settings)}`}
              buttonTextAttribute="button-43a, button-43b"
              buttonType="outlined"
              type="button"
              icon={editButtonImg}
              className="editReportParagraphButton"
              id={`editReportParagraphButton-description-${props.id}`}
              onClick={() => {
                setEditMode(true)
                setShowEditButton(false)
              }}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </div>
        ) : (
          <>
            <div
              className={`reportParagraphTextAreaContainer`}
              id={`reportParagraphTextAreaContainer-description-${props.id}`}
            >
              <textarea
                className={`reportParagraphTextArea ${
                  props.claimIndex !== undefined && !props.treeParagraph
                    ? 'claimParagraph'
                    : undefined
                } ${!editMode && !showEditButton ? 'hideBorders' : undefined}`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                )}
                value={editableText}
                placeholder={props.placeholder}
                id={`reportParagraphTextArea-description-${props.id}`}
                onChange={(e) => setEditableText(e.target.value)}
                //style={{ height: textAreaHeight }}
                maxLength={
                  props.claimIndex !== undefined && !props.treeParagraph
                    ? 1300
                    : undefined
                }
                onClick={() => {
                  setEditMode(true)
                  setShowEditButton(false)
                }}
              />
              <div
                className={
                  props.claimIndex !== undefined && !props.treeParagraph
                    ? 'wordCounterDiv wordForClaims'
                    : 'wordCounterDiv'
                }
                id={`wordCounterDiv-description-${props.id}`}
                style={!editMode ? { display: 'none' } : undefined}
              >
                {editableText && editableText.match(/\S+/g) !== null
                  ? editableText.match(/\S+/g)!.length
                  : 0}{' '}
                words
              </div>
              <div
                className="revertTextAndDeleteContainer"
                id={`revertTextAndDeleteContainer-description-${props.id}`}
                style={!editMode ? { display: 'none' } : undefined}
              >
                <div
                  className={`${
                    isUndoAvailable && !doingRedoUndo ? '' : 'inactive'
                  } thumbnailContainer`}
                  id={`thumbnailContainer1-description-${props.id}`}
                  onClick={
                    isUndoAvailable && !doingRedoUndo ? onClickUndo : undefined
                  }
                  style={
                    isUndoAvailable && !doingRedoUndo
                      ? undefined
                      : { cursor: 'default' }
                  }
                >
                  <img
                    src={
                      isUndoAvailable && !doingRedoUndo ? undoBlue : undoGray
                    }
                    alt="undoImg"
                    className="undoImg"
                    id={`undoImg-description-${props.id}`}
                    onClick={
                      isUndoAvailable && !doingRedoUndo
                        ? onClickUndo
                        : undefined
                    }
                    style={
                      isUndoAvailable && !doingRedoUndo
                        ? undefined
                        : { cursor: 'default' }
                    }
                  />
                </div>
                <div
                  className={`${
                    isRedoAvailable && !doingRedoUndo ? '' : 'inactive'
                  } thumbnailContainer`}
                  id={`thumbnailContainer2-description-${props.id}`}
                  onClick={
                    isRedoAvailable && !doingRedoUndo ? onClickRedo : undefined
                  }
                  style={
                    isRedoAvailable && !doingRedoUndo
                      ? undefined
                      : { cursor: 'default' }
                  }
                >
                  <img
                    src={
                      isRedoAvailable && !doingRedoUndo ? redoBlue : redoGray
                    }
                    alt="redoImg"
                    className="redoImg"
                    id={`redoImg-description-${props.id}`}
                    onClick={
                      isRedoAvailable && !doingRedoUndo
                        ? onClickRedo
                        : undefined
                    }
                    style={
                      isRedoAvailable && !doingRedoUndo
                        ? undefined
                        : { cursor: 'default' }
                    }
                  />
                </div>
                {/* <div
                  className="thumbnailContainer"
                  id={`thumbnailContainer3-description-${props.id}`}
                  onClick={resetTextToDefault}
                >
                  <img
                    src={resetImg}
                    alt="resetImg"
                    className="resetImg"
                    id={`resetImg-description-${props.id}`}
                    onClick={resetTextToDefault}
                  />
                </div> */}
                <div
                  className="thumbnailContainer"
                  id={`thumbnailContainer4-description-${props.id}`}
                  onClick={deleteText}
                >
                  <img
                    src={deleteImg}
                    alt="deleteImg"
                    className="deleteImg"
                    id={`deleteImg-description-${props.id}`}
                  />
                </div>
              </div>
            </div>
            {/* <div
              className="saveParagraphContainer"
              id={`saveParagraphContainer-description-${props.id}`}
            >
              <Button
                label="SAVE"
                buttonType="outlined"
                type="button"
                icon={savePurpleImg}
                className="saveAsButton"
                id={`paragraphSaveButton-description-${props.id}`}
                onClick={() => saveEditedText(editableText)}
              />
            </div> */}
          </>
        )}
      </div>
    </>
  )
}

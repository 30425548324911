import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../models/reportModels/reportEnums'
import {
  ReportDataType,
  ReportWhitelabel,
} from '../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { deepCloneObject } from './commonFunctions'

export function adjustClaimsInReport(
  reportData: ReportDataType,
  currentSnapshot: ScenarioSnapshot,
) {
  let tempReportData = deepCloneObject(reportData) as ReportDataType
  let claimIds = []

  //add a new claim in the report
  for (let claim of currentSnapshot.claims) {
    claimIds.push(claim.id)
    //console.log('Checking claim with claim Id:', claim.id)

    if (
      reportData.sections
        .filter((section) => section.title === 'Claims')[0]
        .contents.claims?.filter(
          (reportClaim) => reportClaim.claimOrTreeId === claim.id,
        ).length === 0
    ) {
      tempReportData.sections
        .filter((section) => section.title === 'Claims')[0]
        .contents.claims!.push({ description: '', claimOrTreeId: claim.id })
      //console.log('Adding claim with claim Id:', claim.id, 'in the report')
    }
  }

  // remove unnecessary claims from the report
  let claimsToDelete = []
  for (const [index, reportClaim] of tempReportData.sections
    .filter((section) => section.title === 'Claims')[0]
    .contents.claims!.entries()) {
    /* console.log(
      'Checking reportClaim with claimOrTreeId:',
      reportClaim.claimOrTreeId,
    ) */

    if (
      currentSnapshot.claims.filter(
        (claim) => claim.id === reportClaim.claimOrTreeId,
      ).length === 0
    ) {
      /* console.log(
        'Deleting reportClaim with claimOrTreeId:',
        reportClaim.claimOrTreeId,
      ) */

      claimsToDelete.push(index)
    }
  }

  for (const claimIndex of claimsToDelete) {
    delete tempReportData.sections.filter(
      (section) => section.title === 'Claims',
    )[0].contents.claims![claimIndex]
  }

  //reorder the claims in the report
  let tempReportClaims = []
  for (let claimId of claimIds) {
    tempReportClaims.push(
      tempReportData.sections
        .filter((section) => section.title === 'Claims')[0]
        .contents.claims!.filter(
          (reportClaim) => reportClaim.claimOrTreeId === claimId,
        )[0],
    )
  }

  tempReportData.sections.filter(
    (section) => section.title === 'Claims',
  )[0].contents.claims = tempReportClaims

  return tempReportData
}

export function snapshotHasLegalCosts(
  currentSnapshot: ScenarioSnapshot,
): boolean {
  return (
    currentSnapshot.legalCosts.included &&
    !(
      !currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .estimatedCosts &&
      !currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .estimatedCosts &&
      !currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.estimatedCosts &&
      !currentSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.estimatedCosts
    )
  )
}

export function snapshotHasIncurredCosts(
  currentSnapshot: ScenarioSnapshot,
): boolean {
  return (
    currentSnapshot.legalCosts.included &&
    !(
      !currentSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .incurredCosts.value &&
      !currentSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .incurredCosts.value &&
      !currentSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.incurredCosts.value &&
      !currentSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.incurredCosts.value
    )
  )
}

export function snapshotHasEnforcementRisk(
  currentSnapshot: ScenarioSnapshot,
): boolean {
  return (
    currentSnapshot.hasBankruptcy &&
    currentSnapshot.bankruptcyPercentage !== undefined &&
    currentSnapshot.bankruptcyPercentage > 0
  )
}

export function claimsOverviewFontSize(word: string) {
  if (word.length > 24) {
    return { fontSize: '5px' }
  } else if (word.length > 23) {
    return { fontSize: '5.5px' }
  } else if (word.length > 22) {
    return { fontSize: '6px' }
  } else if (word.length > 20) {
    return { fontSize: '6.5px' }
  } else if (word.length > 16) {
    return { fontSize: '7px' }
  } else if (word.length > 12) {
    return { fontSize: '8px' }
  } else {
    return { fontSize: '8px' }
  }
}

export const getStyleFromWhitelabel = (
  reportWhitelabel: ReportWhitelabel | undefined,
  key: ReportWhitelabelKeys,
  type: FontLabels,
  forPdf?: boolean,
  noColor?: boolean,
) => {
  let res: any = {}

  if (reportWhitelabel) {
    res = {
      fontFamily:
        reportWhitelabel[key].font !== 'default'
          ? `${reportWhitelabel[key].font}${!forPdf ? '-Local' : ''}${
              type !== '' ? `-${type}` : ''
            }`
          : undefined,
      color:
        reportWhitelabel[key].color !== 'default' && !noColor
          ? reportWhitelabel[key].color
          : undefined,
    }
    if (reportWhitelabel[key].font === 'Arial' && type === FontLabels.light) {
      res['marginTop'] = '3.4px'
    }
  }

  return res
}

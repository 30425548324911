import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { stringAndRoundTo2Decimals } from '../../../../../../../../Modules/DisputeModules/AppFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { getText } from '../../../../../../../../services/textFunctions'
type Props = {
  id: number
  sortedDataForTable: any
  currentSnapshot: ScenarioSnapshot
}
export default function LegalCostsByInsurance(props: Props) {
  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
   { amount_received , amount_received_out_of_court , claim_name, claim_type, claim_type_id, interest_date, interest_rate, interest_received, outcome_probability, scenario_name, tree_name}
    17 - name
    
    */
  const breakdown = props.sortedDataForTable[props.id][16]
  const user = useRecoilValue(userState)

  return (
    <>
      <div className="hoverBackground" data-openreplay-obscured>
        {breakdown.length < 1 ? (
          <>
            <p
              className="hoverTitle"
              id="detailedResultsHoverComponentTitle-legalCostsByInsurance"
              data-textattribute="title-104"
            >
              {getText('title-104', user.settings)}
            </p>
            <div className="pathElementContainer">
              <div
                className="pathElement"
                style={{ backgroundColor: '#ffdddd' }}
                key={`breakdownlist`}
              >
                <p className="claimTitle">
                  {props.sortedDataForTable[props.id][17]}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="hoverTextContainer">
              <p
                className="hoverText"
                id="detailedResultsHoverComponentDescription-legalCostsByInsurance1"
                data-textattribute="description-66"
              >
                {getText('description-66', user.settings)}{' '}
                <span>
                  {stringAndRoundTo2Decimals(
                    parseFloat(props.currentSnapshot.maxInsurance!.toString()),
                    user.settings,
                  )}{' '}
                  {props.currentSnapshot.currency}
                </span>
                .
              </p>

              <p
                className="hoverText"
                id="detailedResultsHoverComponentDescription-legalCostsByInsurance2"
                data-textattribute="description-67"
              >
                {getText('description-67', user.settings)}{' '}
                <span>
                  {stringAndRoundTo2Decimals(
                    parseFloat(
                      props.currentSnapshot.minimumExcessFee!.toString(),
                    ),
                    user.settings,
                  )}{' '}
                  {props.currentSnapshot.currency}
                </span>
                .
              </p>
              <p
                className="hoverText"
                id="detailedResultsHoverComponentDescription-legalCostsByInsurance3"
                data-textattribute="description-68"
              >
                {getText('description-68', user.settings)}{' '}
                <span>
                  {stringAndRoundTo2Decimals(
                    parseFloat(
                      props.currentSnapshot.insuranceExcessPercentage!.toString(),
                    ),
                    user.settings,
                  )}{' '}
                  %
                </span>
                .
              </p>
              <p
                className="hoverText"
                id="detailedResultsHoverComponentDescription-legalCostsByInsurance4"
                data-textattribute="description-69"
              >
                {getText('description-69', user.settings)}{' '}
                <span>
                  {stringAndRoundTo2Decimals(
                    props.sortedDataForTable[props.id][8],
                    user.settings,
                  )}{' '}
                  {props.currentSnapshot.currency}
                </span>
                .
              </p>
            </div>
          </>
        )}
      </div>
    </>
  )
}

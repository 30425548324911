import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  NumericFormat,
  NumberFormatValues,
  SourceInfo,
} from 'react-number-format'
import { InputAdornment, TextField } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import { viewerState } from '../../../states/ViewerState'
import { getText } from '../../../services/textFunctions'
import { recoveryModeState } from '../../../states/RecoveryModeState'
import { freemiumState } from '../../../states/FreemiumState'
import { FreemiumMessageType } from '../../../models/freemiumEnums'
import { formattedNumToString } from '../../../services/formatNum'

type Props = {
  id: string
  name: string
  value: number | undefined
  className?: string
  onChange?: (e: any) => void
  label?: string
  maxNumberOfDecimals: number
  maxValue: number
  minValue?: number
  readOnly?: boolean
  suffix?: string
  suffixFontSize?: number
  prefix?: any
  helperText?: string
  hideHelperText?: boolean
  smallField?: boolean
  error?: boolean
  errorMessage?: string
  placeholderPadding?: string
  width: number
  tagcolor?: string
  borderColor?: string
  backgroundColor?: string
  boldBorder?: boolean
  onClick?: () => void
  onFocus?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  localStyle?: CSSProperties
  tabIndex?: number
  autoComplete?: boolean
  noFormat?: boolean
  alwaysShrink?: boolean
  treeProbability?: boolean
  defaultZero?: boolean
  labelTextAttribute: `label-${number}`
  errorTextAttribute?: `error-${number}`
  helperTextAttribute?: `helperText-${number}`
  disabled?: boolean
  disabledMessage?: string
  disabledMessageTextAttribute?: `message-${number}`
  absoluteDisabledMessage?: boolean
  noDelay?: boolean
  allowNegative?: boolean
  stickySuffix?: any
  stickySuffixMargin?: number
}

const InputNum = (props: Props) => {
  const user = useRecoilValue(userState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const [formattedValue, setFormattedValue] = useState(
    props.value ? formattedNumToString(-props.value, user.settings) : '',
  )

  const shrinkFont = props.stickySuffix && formattedValue.length > 11

  const [hasFocus, setHasFocus] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const [localValue, setLocalValue] = useState<number | undefined>(props.value)
  const [changeValue, setChangeValue] = useState<boolean>(false)
  const timer = useRef<NodeJS.Timeout | undefined>(undefined)

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  useEffect(() => {
    if (localValue === 0 || localValue === undefined) {
      if (hasFocus === false) {
        if (props.defaultZero) {
          setFormattedValue('0')
        } else {
          setFormattedValue('')
        }
      } else {
        setFormattedValue('')
      }
    } else {
      setFormattedValue(
        props.value ? formattedNumToString(-props.value, user.settings) : '',
      )
    }
    // eslint-disable-next-line
  }, [hasFocus, props.value])

  const onChange = (value: NumberFormatValues, sourceInfo: SourceInfo) => {
    setFormattedValue(value.formattedValue)

    // Prevents running handleChange from undo/redo
    if (sourceInfo.source === 'event') {
      if (props.defaultZero) {
        if (
          sourceInfo.event &&
          ((sourceInfo.event.nativeEvent as any).inputType ===
            'deleteContentBackward' ||
            (sourceInfo.event.nativeEvent as any).inputType ===
              'deleteContentForward') &&
          value.value === ''
        ) {
          setIsDirty(false)
        } else {
          setIsDirty(true)
        }
      }
    }

    let localnum =
      props.defaultZero && value.floatValue === undefined ? 0 : value.floatValue

    if (props.noDelay && props.onChange) {
      props.onChange({
        target: { value: localnum, id: props.id, name: props.name },
      })
    } else {
      setLocalValue(localnum)

      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        setChangeValue(true)
      }, 200)
    }
  }

  const shouldCallOnChange = () => {
    if (!changeValue || !props.onChange || props.noDelay) {
      return false
    }

    if (
      (localValue === undefined && props.value === undefined) ||
      (localValue === 0 && props.value === 0)
    ) {
      return false
    }

    if (localValue && props.value) {
      if (props.allowNegative) {
        return localValue !== props.value
      } else {
        return Math.abs(localValue ?? 0) !== Math.abs(props.value ?? 0)
      }
    }

    return true
  }

  useEffect(() => {
    if (shouldCallOnChange()) {
      props.onChange!({
        target: { value: localValue, id: props.id, name: props.name },
      })
    }

    setChangeValue(false)
    // eslint-disable-next-line
  }, [changeValue])

  useEffect(() => {
    if (!props.noDelay) {
      setLocalValue(props.value)
    }
    // eslint-disable-next-line
  }, [props.value])

  const onBlur = () => {
    setIsDirty(false)
    setHasFocus(false)

    if (localValue !== props.value && props.onChange && !props.noDelay) {
      if (
        localValue === undefined ||
        props.value === undefined ||
        Math.abs(localValue) !== Math.abs(props.value)
      ) {
        props.onChange({
          target: { value: localValue, id: props.id, name: props.name },
        })
      }
    }
  }

  const getValue = () => {
    const valueToGet = props.noDelay ? props.value : localValue

    if (
      (hasFocus &&
        props.defaultZero &&
        valueToGet === 0 &&
        !isDirty &&
        !props.readOnly) ||
      valueToGet === undefined
    ) {
      return ''
    }

    return valueToGet
  }

  const onClickDisabled = () => {
    if (props.disabled) {
      const element = document.getElementById(`${props.id}-container`)
      const viewportOffset = element!.getBoundingClientRect()

      let top = viewportOffset.top - 15
      const left = viewportOffset.left + 50
      const h = window.innerHeight
      if (h - top < 200) {
        top -= 130
      }

      if (freemium.isFreemium) {
        setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      } else if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
        setDisabledMessageCoordinates([top, left])
      }
    }
  }

  return (
    <>
      <div
        className="input inputNum"
        id={`${props.id}-container`}
        onClick={onClickDisabled}
        style={props.disabled ? { cursor: 'not-allowed' } : {}}
        data-openreplay-obscured
      >
        <NumericFormat
          name={`numberFormat${props.name}`}
          customInput={TextField}
          thousandSeparator={
            props.noFormat
              ? ''
              : user.settings.formats.numberFormat.thousandSeparator
          }
          allowNegative={props.allowNegative === true ? true : false}
          decimalSeparator={user.settings.formats.numberFormat.decimalSeparator}
          allowedDecimalSeparators={['.', ',']}
          onValueChange={onChange}
          InputProps={{
            endAdornment: props.suffix ? (
              <InputAdornment position="start">{props.suffix}</InputAdornment>
            ) : undefined,
            startAdornment: props.prefix ? (
              <InputAdornment position="start">{props.prefix}</InputAdornment>
            ) : undefined,
            size: props.smallField ? 'small' : 'medium',
          }}
          data-textattribute={props.labelTextAttribute}
          InputLabelProps={{ shrink: props.alwaysShrink }}
          inputProps={{ readOnly: props.readOnly }}
          disabled={props.disabled}
          id={props.id}
          value={getValue()}
          label={props.label}
          variant="outlined"
          className={props.className}
          error={props.error}
          data-errortextattribute={props.errorTextAttribute}
          helperText={
            props.hideHelperText
              ? ''
              : props.error === true
              ? props.errorMessage
              : props.helperText
              ? props.helperText
              : ' '
          }
          data-helpertextattribute={
            props.error === true
              ? props.errorTextAttribute
              : props.helperTextAttribute
              ? props.helperTextAttribute
              : ''
          }
          onFocus={() => {
            setIsDirty(false)
            setHasFocus(true)
            if (props.onFocus) {
              props.onFocus()
            }
          }}
          onBlur={onBlur}
          sx={{
            width: props.width,
            height: props.smallField ? 35 : 50,
            borderColor: 'transparent',

            '& .MuiInputLabel-root': {
              paddingRight: props.placeholderPadding
                ? props.placeholderPadding
                : '26px',

              color: props.tagcolor ? props.tagcolor : '',
              fontWeight: props.boldBorder ? 600 : 400,
            },
            '& .MuiInputLabel-root.Mui-focused': {
              paddingRight: '0px',
              borderColor:
                props.disabled && !props.borderColor
                  ? 'rgba(0, 0, 0, 0.23)'
                  : props.borderColor,
            },
            '& .Mui-focused': {
              '& > fieldset': {
                borderColor: `${
                  props.disabled && !props.borderColor
                    ? 'rgba(0, 0, 0, 0.23)'
                    : props.borderColor
                } !important`,
                borderWidth:
                  props.boldBorder && !props.disabled
                    ? '3px !important'
                    : '2px',
              },
            },
            '& .MuiInputLabel-shrink': {
              paddingRight: '0px',
            },
            '& .MuiInputBase-input': {
              color: props.readOnly
                ? 'rgba(0, 0, 0, 0.23)'
                : props.localStyle?.color ?? '#201a2d',
              cursor: props.disabled
                ? 'not-allowed'
                : props.readOnly
                ? 'default'
                : 'text',
              fontSize: shrinkFont ? '11px' : '16px',
              padding: shrinkFont ? '13px 2px 11px 0px' : '',
            },

            '& .MuiOutlinedInput-notchedOutline': {
              borderColor:
                props.disabled && !props.borderColor
                  ? 'rgba(0, 0, 0, 0.23)'
                  : `${props.borderColor} !important`,
              borderWidth: props.boldBorder ? '2px' : '1px',
            },
            '&:hover fieldset': {
              borderColor:
                props.disabled && !props.borderColor
                  ? 'rgba(0, 0, 0, 0.23)'
                  : props.borderColor,
            },
            '& .MuiOutlinedInput-root:hover': {
              '& > fieldset': {
                borderColor:
                  props.disabled && !props.borderColor
                    ? 'rgba(0, 0, 0, 0.23)'
                    : props.borderColor,
                borderWidth:
                  props.boldBorder && !props.disabled
                    ? '3px !important'
                    : '2px',
              },
            },
            '& .MuiInputBase-root': {
              backgroundColor: props.backgroundColor
                ? props.backgroundColor
                : undefined,
              paddingLeft: props.treeProbability ? '15px' : '',
              cursor: props.disabled ? 'not-allowed' : 'default',
            },
            '& .MuiInputAdornment-root': {
              cursor: props.disabled ? 'not-allowed' : 'default',
              '& > .MuiTypography-root': {
                color: props.disabled
                  ? 'rgba(0, 0, 0, 0.23)'
                  : props.readOnly
                  ? 'rgba(0, 0, 0, 0.23)'
                  : '#201a2d',
                cursor: props.disabled ? 'not-allowed' : 'default',
                fontSize: shrinkFont
                  ? '13px'
                  : props.suffixFontSize
                  ? `${props.suffixFontSize}px`
                  : '16px',
              },
            },
            '& label': props.disabled
              ? {
                  cursor: 'not-allowed',
                }
              : {},
            '&.Mui-disabled': {
              '& > div': { cursor: 'not-allowed' },
            },
            '& .MuiFormHelperText-root': {
              lineHeight: 1.25,
              color: props.error ? '##ac2828' : '#8e8e8e', //MondayGray
            },
          }}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}
          autoComplete={props.autoComplete ? 'on' : 'off'}
          decimalScale={props.maxNumberOfDecimals}
          allowLeadingZeros={false}
          style={props.localStyle}
          isAllowed={(values) => {
            const { floatValue } = values

            if (props.allowNegative) {
              return (
                (floatValue !== undefined &&
                  floatValue >= (props.minValue ?? 0) &&
                  floatValue <= props.maxValue) ||
                floatValue === undefined
              )
            }
            return (
              (floatValue !== undefined &&
                floatValue >= 0 &&
                floatValue <= props.maxValue) ||
              floatValue === undefined
            )
          }}
        />
        {props.stickySuffix ? (
          <div
            className="stickySuffixContainer"
            style={{
              left: props.stickySuffixMargin ?? 20,
            }}
          >
            <div
              className="stickySuffixPlaceholder"
              id={`stickySuffixPlaceholder-${props.id}`}
              style={shrinkFont ? { fontSize: '11px' } : undefined}
            >
              {formattedValue}
            </div>
            <div
              className="stickySuffix"
              style={{
                color: props.localStyle?.color
                  ? props.localStyle?.color
                  : 'none',
              }}
              onClick={() => {
                document.getElementById(props.id)?.focus()
              }}
            >
              {props.stickySuffix}
            </div>
          </div>
        ) : null}
        {props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={onClickDisabled}
          ></div>
        )}
      </div>
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={props.disabledMessageTextAttribute}
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
          absolute={props.absoluteDisabledMessage}
        />
      )}
    </>
  )
}

export default InputNum

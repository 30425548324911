import React, { useState, useEffect, useRef } from 'react'
import {
  AccessRole,
  ClaimType,
  ClaimsPreviewStyle,
  Currency,
} from '../../../../../../models/enums'
import { IndependentClaim } from '../../../../../../models/independentClaim'

import circleMenuImg from '../../../../../../resources/images/104-circleWithArrow.svg'
import DeleteClaimWindowForPreview from '../../../../../../Components/DeleteWindow/DeleteWindowForPreview'
import { formattedNumToString } from '../../../../../../services/formatNum'
import fitty from 'fitty'
import {
  roundNumberTo,
  claimTileFontSize,
  removeUnderscore,
} from '../../../../../../services/commonFunctions'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'
import { editTreeNodeFromUndoState } from '../../../../../../states/EditTreeNodeFromUndo'
import { convertDateTimeToLocal } from '../../../../../../services/dateFunctions'
import QuantumComponentForClaimTile from './QuantumComponentForClaimTile'
import ClaimTile3DotsMenu from '../ClaimTile3DotsMenu'
import Draggable from 'react-draggable'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import Symbol from '../../../../../../Components/symbol/Symbol'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Props = {
  claimInfo: IndependentClaim
  claimIndex: number
  claimType: ClaimType
  currency: Currency
  isActive: boolean
  setActiveClaim: (param: number) => void
  claimPreviewId: string
  errors: number
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  forReport?: boolean
  ownRole?: AccessRole
  claimPositionHighlight: number | undefined
  claimIsDragging: number | undefined
  handleDraggingClaim: (data: any) => void
  handleChangeClaimOrder: () => void
  reportWhitelabel?: ReportWhitelabel
  partiesNames?: string[]
}

export default function IndependentClaimTile(props: Props) {
  const [moreInfoMenu, setMoreInfoMenu] = useState(false)
  const [isExternalConsequenceOn, setIsExternalConsequenceOn] = useState(false)
  const [threeDotsMenuOn, setThreeDotsMenuOn] = useState(false)
  const [hoverClaimTile, setHoverClaimTile] = useState(false)
  const nodeRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)

  const onMouseDown = (e: any) => {
    if (!e.target.className.includes(`noCollapse-${props.claimIndex}`)) {
      setMoreInfoMenu(false)
    }

    // eslint-disable-next-line
  }
  const [removeClaimMenuOn, setRemoveClaimMenuOn] = useState(false)
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const setEditTreeNodeFromUndo = useSetRecoilState(editTreeNodeFromUndoState)

  useEffect(() => {
    if (moreInfoMenu) {
      document.addEventListener('mousedown', onMouseDown, false)
    }
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [moreInfoMenu])

  useEffect(() => {
    if (!props.forReport) {
      setTimeout(() => {
        fitty('.claimAmountText', {
          minSize: 9,
          maxSize: 16,
          multiLine: false,
        })
      }, 10)
    }
    // eslint-disable-next-line
  }, [props.claimInfo])

  useEffect(() => {
    const tempIsExternalConsequenceOn =
      props.claimInfo.quantumScenarios.filter(
        (quantumScenario) => quantumScenario.outOfCourtAmount !== 0,
      ).length > 0 && props.claimInfo.hasOutOfCourt

    setIsExternalConsequenceOn(tempIsExternalConsequenceOn)
  }, [props.claimInfo])

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        handle=".moveTileTransparentCover, .singlClaimUpperPartClaim"
        onDrag={(e: any, data) => {
          if (data.x !== 0 || data.y !== 0) {
            setIsDragging(true)
          }

          props.handleDraggingClaim(data)
        }}
        onStop={() => {
          setTimeout(() => {
            setIsDragging(false)
          }, 20)

          props.handleChangeClaimOrder()
        }}
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: 0, y: 0 }}
      >
        <div
          className={
            props.forReport
              ? `singleClaimPreviewContainer pdfPreview noCollapse-${props.claimIndex}`
              : `singleClaimPreviewContainer noCollapse-${props.claimIndex}`
          }
          id={`singleClaimPreviewContainer-${props.claimIndex}`}
          onMouseEnter={() => setHoverClaimTile(true)}
          onMouseLeave={() => setHoverClaimTile(false)}
          data-openreplay-obscured
          ref={nodeRef}
          style={
            !props.forReport && moreInfoMenu ? { zIndex: 1 } : { zIndex: 0 }
          }
        >
          {props.forReport && (
            <div
              className={`partyContainer ${krogerusColors ? 'krogerus' : ''}`}
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                false,
                true,
              )}
              data-openreplay-obscured
            >
              <p className="partyContainerText">
                {props.partiesNames
                  ? props.claimType === ClaimType.claim
                    ? `${removeUnderscore(props.partiesNames[0])}'s Claim`
                    : `${removeUnderscore(props.partiesNames[1])}'s Claim`
                  : ''}
              </p>
            </div>
          )}
          <div
            className={`singlClaimUpperPartClaim noCollapse-${
              props.claimIndex
            } ${props.isActive ? 'active' : ''} ${
              props.forReport ? 'forReport' : ''
            } ${krogerusColors ? 'krogerus' : ''}`}
            id={`singlClaimUpperPartClaim-${props.claimIndex}`}
            onClick={() => {
              if (isDragging === false) {
                props.setActiveClaim(props.claimIndex)
                setEditTreeNodeFromUndo(undefined)
              }
            }}
            style={props.forReport ? { cursor: 'default' } : undefined}
          >
            <div
              className={`previewClaimName noCollapse-${props.claimIndex}`}
              id={`previewIndependentClaimName-${props.claimIndex}`}
              data-openreplay-obscured
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                false,
                true,
              )}
            >
              {props.claimInfo.name}
            </div>

            {props.errors > 0 && <div className="errors">{props.errors}</div>}
          </div>
          <div
            className={
              props.forReport
                ? `singlClaimDownPartClaim pdfPreview noCollapse-${props.claimIndex}`
                : `singlClaimDownPartClaim noCollapse-${props.claimIndex}`
            }
            id={`singlClaimDownPartClaim-${props.claimIndex}`}
            style={props.forReport ? { cursor: 'default' } : undefined}
          >
            <div
              className={`firstRow noCollapse-${props.claimIndex}`}
              data-openreplay-obscured
              onClick={() => props.setActiveClaim(props.claimIndex)}
            >
              <div
                className={`claimAmountTextContainer noCollapse-${props.claimIndex}`}
                id={`claimAmountTextContainer-${props.claimIndex}`}
              >
                <Symbol
                  symbolType={
                    props.claimType === 'claim' ? 'client' : 'opposingParty'
                  }
                  targetId={`claimAmountTextContainer-${props.claimIndex}`}
                  targetType={'tileText'}
                />
                <p
                  className={
                    props.claimType === 'claim'
                      ? `claimAmountText noCollapse-${props.claimIndex} ${
                          krogerusColors ? 'krogerus' : ''
                        }`
                      : `claimAmountText negative noCollapse-${
                          props.claimIndex
                        } ${krogerusColors ? 'krogerus' : ''}`
                  }
                  id={`claimAmountText-${props.claimIndex}`}
                  style={
                    props.forReport
                      ? {
                          cursor: 'default',
                          ...claimTileFontSize(
                            formattedNumToString(
                              props.claimInfo.amount as number,
                              user.settings,
                            ),
                          ),
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            false,
                            true,
                          ),
                        }
                      : undefined
                  }
                >
                  {props.claimInfo.amount !== undefined
                    ? formattedNumToString(
                        props.claimInfo.amount as number,
                        user.settings,
                        true,
                      )
                    : 0}{' '}
                  {props.currency}
                </p>
              </div>
              <p
                className={`interestRateText noCollapse-${props.claimIndex}`}
                id={`interestRateTextClaimPreview-${props.claimIndex}`}
                style={
                  document.getElementById(
                    `interestRateTextClaimPreview-${props.claimIndex}`,
                  ) &&
                  document.getElementById(
                    `interestRateTextClaimPreview-${props.claimIndex}`,
                  )!.innerText.length > 14
                    ? props.forReport
                      ? {
                          fontSize: '13px',
                          cursor: 'default',
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                          ),
                        }
                      : { fontSize: '13px' }
                    : props.forReport
                    ? {
                        cursor: 'default',
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                        ),
                      }
                    : undefined
                }
                data-textattribute={
                  props.claimInfo.interestRate! > 0 ? 'title-88' : 'title-89'
                }
              >
                {props.claimInfo.interestRate! > 0
                  ? `${formattedNumToString(
                      props.claimInfo.interestRate!,
                      user.settings,
                    )} ${getText('title-88', user.settings)}`
                  : getText('title-89', user.settings)}
              </p>
            </div>
            <div
              className={`secondRow noCollapse-${props.claimIndex}`}
              data-openreplay-obscured
              onClick={() => props.setActiveClaim(props.claimIndex)}
            >
              {props.claimInfo.isInterest &&
              props.claimInfo.interestDate !== '' ? (
                <p
                  className={
                    props.claimInfo.interestDate === undefined ||
                    props.claimInfo.interestDate === 'Invalid Date'
                      ? `interestDate error noCollapse-${props.claimIndex}`
                      : `interestDate noCollapse-${props.claimIndex}`
                  }
                  id={`interestDateTextClaimPreview-${props.claimIndex}`}
                  data-textattribute="title-90"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                  )}
                >
                  {props.claimInfo.interestDate === undefined ||
                  props.claimInfo.interestDate === 'Invalid Date'
                    ? getText('title-90', user.settings)
                    : convertDateTimeToLocal(
                        props.claimInfo.interestDate as string,
                        user.settings,
                        true,
                      )}
                </p>
              ) : null}
            </div>
            {!removeClaimMenuOn ? (
              <div
                className={`moreInfoMenuContainer noCollapse-${props.claimIndex}`}
                onClick={() => setMoreInfoMenu(true)}
                id={`moreInfoMenuContainer-${props.claimIndex}`}
              >
                {props.forReport || moreInfoMenu ? (
                  <>
                    <div
                      className={`moreInfoMenu noCollapse-${props.claimIndex} ${
                        props.forReport ? 'moreInfoMenuPreviewPdf' : undefined
                      }`}
                      style={props.forReport ? { zIndex: 0 } : undefined}
                      id={`moreInfoMenu-${props.claimIndex}`}
                      data-openreplay-obscured
                    >
                      {props.claimInfo.liabilityRequirements.length > 0 ? (
                        <>
                          <p
                            className={`liabilityListTitle noCollapse-${props.claimIndex}`}
                            id={`liabilityListTitle-${props.claimIndex}`}
                            data-textattribute="title-204"
                            style={getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.bold,
                            )}
                          >
                            {getText('title-204', user.settings)}
                          </p>
                          <div
                            className={`liabilityListComponent noCollapse-${props.claimIndex}`}
                            id={`liabilityListComponent-${props.claimIndex}`}
                          >
                            {props.claimInfo.liabilityRequirements.map(
                              (item, index) => (
                                <div
                                  className={`singleLiabilityRow noCollapse-${props.claimIndex}`}
                                  key={`liability-${props.claimIndex}_${index}`}
                                  id={`singleLiabilityRow-${props.claimIndex}_${index}`}
                                >
                                  <p
                                    className={`liabilityQuestion noCollapse-${props.claimIndex}`}
                                    id={`liabilityPreviewTitle-${props.claimIndex}_${index}`}
                                    style={getStyleFromWhitelabel(
                                      props.reportWhitelabel,
                                      ReportWhitelabelKeys.sectionDescription,
                                      FontLabels.regular,
                                    )}
                                  >
                                    {item.title}
                                  </p>
                                  <p
                                    className={`liabilityProbability noCollapse-${props.claimIndex}`}
                                    id={`liabilityPreviewProbability-${props.claimIndex}_${index}`}
                                    style={getStyleFromWhitelabel(
                                      props.reportWhitelabel,
                                      ReportWhitelabelKeys.sectionDescription,
                                      FontLabels.regular,
                                    )}
                                  >
                                    {formattedNumToString(
                                      roundNumberTo(item.probability, 2),
                                      user.settings,
                                    )}{' '}
                                    %
                                  </p>
                                </div>
                              ),
                            )}
                            <div
                              className={`totalLiabilityRow noCollapse-${props.claimIndex}`}
                            >
                              <p
                                className={`totalLiabilityTitle noCollapse-${props.claimIndex}`}
                                id={`totalLiabilityTitle-${props.claimIndex}`}
                                data-textattribute="title-87"
                                style={getStyleFromWhitelabel(
                                  props.reportWhitelabel,
                                  ReportWhitelabelKeys.sectionDescription,
                                  FontLabels.regular,
                                )}
                              >
                                {getText('title-87', user.settings)}
                              </p>
                              <p
                                className={`totalLiabilityProbability noCollapse-${props.claimIndex}`}
                                id={`totalLiabilityProbability-${props.claimIndex}`}
                                data-textattribute={
                                  props.claimInfo.liability * 100 < 0.01
                                    ? 'title-91'
                                    : ''
                                }
                                style={getStyleFromWhitelabel(
                                  props.reportWhitelabel,
                                  ReportWhitelabelKeys.sectionDescription,
                                  FontLabels.regular,
                                )}
                              >
                                {props.claimInfo.liability * 100 < 0.01
                                  ? getText('title-91', user.settings)
                                  : formattedNumToString(
                                      roundNumberTo(
                                        props.claimInfo.liability * 100,
                                        2,
                                      ),
                                      user.settings,
                                    )}{' '}
                                %
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <p
                        className={`quantumTitle noCollapse-${props.claimIndex}`}
                        id={`quantumTitle-${props.claimIndex}`}
                        data-textattribute="title-205"
                        style={getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.bold,
                        )}
                      >
                        {getText('title-205', user.settings)}
                      </p>
                      <div
                        className={`quantumScenariosListComponent noCollapse-${props.claimIndex}`}
                      >
                        <div
                          className="quantumSubtitlesContainer"
                          id={`quantumSubtitlesContainer-${props.claimIndex}`}
                        >
                          <p
                            className={`quantumSubtitleAmount`}
                            id={`quantumSubtitleAmount-${props.claimIndex}`}
                            data-textattribute="title-206"
                            style={getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                            )}
                          >
                            {getText('title-206', user.settings)}
                          </p>
                          {isExternalConsequenceOn && (
                            <p
                              className="quantumSubtitleExternalValue"
                              id={`quantumSubtitleExternalValue-${props.claimIndex}`}
                              data-textattribute="title-207"
                              style={getStyleFromWhitelabel(
                                props.reportWhitelabel,
                                ReportWhitelabelKeys.sectionDescription,
                                FontLabels.regular,
                              )}
                            >
                              {getText('title-207', user.settings)}
                            </p>
                          )}
                        </div>
                        {props.claimInfo.quantumProbabilities.map(
                          (probability, index) => (
                            <QuantumComponentForClaimTile
                              key={`quantumComponentForClaimPreview-${index}`}
                              quantumAmount={
                                props.claimInfo.quantumScenarios[index]
                                  .amount ?? 0
                              }
                              quantumProbability={probability}
                              externalConsequenceSignum={
                                props.claimInfo.quantumScenarios[index]
                                  .outOfCourtSignum
                              }
                              externalConsequenceAmount={
                                props.claimInfo.quantumScenarios[index]
                                  .outOfCourtAmount ?? 0
                              }
                              isExternalConsequenceOn={isExternalConsequenceOn}
                              quantumIndex={index}
                              claimIndex={props.claimIndex}
                              currency={props.currency}
                              reportWhitelabel={props.reportWhitelabel}
                            />
                          ),
                        )}
                      </div>
                    </div>
                    {!props.forReport && (
                      <div className={`moreInfoButtonContainer collapse`}>
                        <p
                          className={`moreInfoButtonText`}
                          id={`moreInfoButtonText-on-${props.claimIndex}`}
                          data-textattribute="submenu-10"
                        >
                          {getText('submenu-10', user.settings)}
                        </p>
                        <img
                          className={`moreInfoButtonOn`}
                          src={circleMenuImg}
                          alt="circleMenuImg"
                          onClick={() => setMoreInfoMenu(false)}
                          id={`moreInfoButtonOn-${props.claimIndex}`}
                        />
                      </div>
                    )}
                    {/* {!props.menuAlwaysOpen && (
                    <div className="transparent-Background"></div>
                  )} */}
                  </>
                ) : !props.forReport ? (
                  <div
                    className="moreInfoButtonContainer"
                    id={`moreInfoButtonContainer-${props.claimIndex}`}
                  >
                    <p
                      className="moreInfoButtonText"
                      id={`moreInfoButtonText-off-${props.claimIndex}`}
                      data-textattribute="submenu-11"
                    >
                      {getText('submenu-11', user.settings)}
                    </p>
                    <img
                      className={'moreInfoButtonOff'}
                      src={circleMenuImg}
                      alt="circleMenuImg"
                      onClick={() => setMoreInfoMenu(false)}
                      id={`moreInfoButtonOff-${props.claimIndex}`}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {!props.forReport &&
              (hoverClaimTile || threeDotsMenuOn) &&
              !removeClaimMenuOn &&
              props.ownRole !== AccessRole.VIEWER && (
                <ClaimTile3DotsMenu
                  claimIndex={props.claimIndex}
                  threeDotsMenuOn={threeDotsMenuOn}
                  setThreeDotsMenuOn={setThreeDotsMenuOn}
                  setRemoveClaimMenuOn={setRemoveClaimMenuOn}
                  claimType={ClaimType.claim}
                  setActiveClaim={props.setActiveClaim}
                />
              )}
            {!props.forReport && removeClaimMenuOn && (
              <div
                className="claimRemoveMenuContainer"
                id={`claimRemoveMenuContainer-${props.claimIndex}`}
              >
                <DeleteClaimWindowForPreview
                  claimIndex={props.claimIndex}
                  claimType={props.claimType}
                  previewStyle={ClaimsPreviewStyle.tiles}
                  deleteFunction={() => {
                    props.removeClaim(
                      props.claimIndex,
                      `removeClaimImgPreview-${props.claimIndex}`,
                      `removeClaimImgPreview-${props.claimIndex}`,
                    )
                    setRemoveClaimMenuOn(false)
                  }}
                  cancelFunction={() => {
                    setRemoveClaimMenuOn(false)
                  }}
                />
              </div>
            )}
          </div>
          {props.claimPositionHighlight === props.claimIndex && (
            <div
              className={
                props.claimPositionHighlight > props.claimIsDragging!
                  ? 'claimPositionHighlight right'
                  : 'claimPositionHighlight left'
              }
              id={`claimPositionHighlight-${props.claimIndex}`}
            ></div>
          )}
        </div>
      </Draggable>
    </>
  )
}

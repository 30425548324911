import { DateTime } from 'luxon'
import { TypeOfInstance } from '../../../../../../../../models/enums'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import {
  ReportProceedingsType,
  ReportStateType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getText } from '../../../../../../../../services/textFunctions'
import { User } from '../../../../../../../../models/user'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'

type Props = {
  defaultInstance: TypeOfInstance
  trial: 'first' | 'second'
  date: string
  colors: { [key: string]: string }
  reportDetails: ReportStateType
  user: User
  proceedings: ReportProceedingsType
  reportWhitelabel?: ReportWhitelabel
}

export default function DateOfTrialComponentDocument(props: Props) {
  /* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
  const styles = StyleSheet.create({
    dateOfTrialContainer: {
      marginTop: '3px',
    },
    dateOfTrialContainerSecond: {
      marginTop: '3px',
    },
    dateOfTrialTitleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
    dateOfTrialTitle: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          true,
        ).fontFamily ?? 'Poppins-Medium',
      marginTop: '3px',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.medium,
        true,
      ).color,
    },
    dateOfTrialDateContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '0px',
    },
    dateOfTrialDate: {
      marginLeft: '3px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.semiBold,
          true,
        ).fontFamily ?? 'Poppins-SemiBold',
      fontSize: '8px',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.semiBold,
        true,
      ).color,
    },
    dateOfTrialImg: {
      height: '11px',
    },
    dateOfTrialIntroText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).color,
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        true,
      ).marginTop,
    },
  })
  const typesOfTrialTitles = {
    //id='reportDateOfTrial'
    first: {
      //data-textattribute='title-121'
      Arbitration: getText('title-121', props.user.settings),
      //data-textattribute='title-122'
      Public_Court: getText('title-122', props.user.settings),
      //data-textattribute='title-123'
      Other: getText('title-123', props.user.settings),
    },
    second: {
      //data-textattribute='title-124'
      Arbitration: getText('title-124', props.user.settings),
      //data-textattribute='title-124'
      Public_Court: getText('title-124', props.user.settings),
      //data-textattribute='title-125'
      Other: getText('title-125', props.user.settings),
    },
  }
  //id='reportEstimatedDate'
  //data-textattribute='title-126'
  const introText = getText('title-126', props.user.settings)

  const introTextFirst =
    props.proceedings.firstCourtDateDescription &&
    props.proceedings.firstCourtDateDescription.length > 0
      ? props.proceedings.firstCourtDateDescription
      : introText
  const introTextSecond =
    props.proceedings.secondCourtDateDescription &&
    props.proceedings.secondCourtDateDescription.length > 0
      ? props.proceedings.secondCourtDateDescription
      : introText

  const trialTitleFirst =
    props.proceedings.firstCourtName.length > 0
      ? props.proceedings.firstCourtName
      : typesOfTrialTitles[props.trial][props.defaultInstance]
  const trialTitleSecond =
    props.proceedings.secondCourtName.length > 0
      ? props.proceedings.secondCourtName
      : typesOfTrialTitles[props.trial][props.defaultInstance]
  return (
    <View
      style={
        props.trial === 'first'
          ? styles.dateOfTrialContainer
          : styles.dateOfTrialContainerSecond
      }
      wrap={false}
    >
      <View style={styles.dateOfTrialTitleContainer}>
        <Text style={styles.dateOfTrialTitle}>
          {props.trial === 'first' ? trialTitleFirst : trialTitleSecond}
        </Text>
      </View>
      <View style={styles.dateOfTrialDateContainer}>
        <Text style={styles.dateOfTrialIntroText}>
          {props.trial === 'first' ? introTextFirst : introTextSecond}
        </Text>
        <Text style={styles.dateOfTrialDate}>
          : {DateTime.fromISO(props.date as string).toFormat('dd LLL yyyy')}
        </Text>
      </View>
    </View>
  )
}

import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer'
import clientImg from '../../../../../../../../resources/images/279-clientSymbol.jpg'
import krogerusClientImg from '../../../../../../../../resources/images/303-clientSymbolKrogerus.jpg'
import opposingPartyImg from '../../../../../../../../resources/images/280-opposingPartySymbol.jpg'
import krogerusOpposingPartyImg from '../../../../../../../../resources/images/304-krogerusOpposingPartySymbol.jpg'
import reduceClientImg from '../../../../../../../../resources/images/295-reducedInputClient-NoParenthesis.jpg'
import krogerusReduceClientImg from '../../../../../../../../resources/images/309-krogerusReducedInputClient-NoParenthesis.jpg'
import reduceOpposingPartyImg from '../../../../../../../../resources/images/296-reducedInputOpposingParty-NoParenthesis.jpg'
import krogerusReduceOpposingPartyImg from '../../../../../../../../resources/images/310-krogerusReducedInputOpposingParty-NoParenthesis.jpg'
import externalClientImg from '../../../../../../../../resources/images/284-positiveExternalClient.jpg'
import krogerusExternalClientImg from '../../../../../../../../resources/images/305-krogerusPositiveExternalClient.jpg'
import externalOpposingPartyImg from '../../../../../../../../resources/images/291-positiveExternalOpposingParty.jpg'
import krogerusExternalOpposingPartyImg from '../../../../../../../../resources/images/317-krogerusPositiveExternalOpposingParty.jpg'
import {
  atLeastOneTreeHasExternalValue,
  atLeastOneTreeHasReducedAmounts,
} from '../../../../../../../../services/resultsFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { removeUnderscore } from '../../../../../../../../services/commonFunctions'
import { getText } from '../../../../../../../../services/textFunctions'
import { User } from '../../../../../../../../models/user'

type Props = {
  currentSnapshot: ScenarioSnapshot
  user: User
  reportWhitelabel?: ReportWhitelabel
  krogerusColors: boolean
}

const ClaimsLegendDocument = (props: Props) => {
  const styles = StyleSheet.create({
    claimsPreviewMemo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      width: '100%',
    },
    claimsPreviewMemoColumn: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginRight: '10px',
      maxWidth: '90px',
    },
    claimsPreviewMemoText: {
      maxWidth: '90px',
      textAlign: 'left',
      textOverflow: 'ellipsis',
      maxLines: 1,
      fontSize: '7px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        true,
      ).color,
    },
    claimsPreviewMemoTextBold: {
      maxWidth: '90px',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      maxLines: 1,
      fontSize: '7px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.semiBold,
          true,
        ).fontFamily ?? 'Poppins-SemiBold',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        true,
      ).color,
    },
    claimsPreviewMemoImageContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    claimsPreviewMemoImage1: {
      height: '6px',
    },
    claimsPreviewMemoImage1Client: {
      height: '7px',
    },
    claimsPreviewMemoImage2: {
      height: '11px',
      marginLeft: '4px',
    },
    claimsPreviewMemoImage3: {
      height: '7px',
    },
    claimsPreviewMemoImage3Client: {
      height: '7.5px',
    },
  })

  return (
    <View wrap={false} style={styles.claimsPreviewMemo}>
      <View
        style={{
          ...styles.claimsPreviewMemoColumn,
          alignItems: 'flex-start',
          height:
            !atLeastOneTreeHasReducedAmounts(props.currentSnapshot) &&
            !atLeastOneTreeHasExternalValue(props.currentSnapshot)
              ? '30px'
              : !atLeastOneTreeHasReducedAmounts(props.currentSnapshot) ||
                !atLeastOneTreeHasExternalValue(props.currentSnapshot)
              ? '45px'
              : '60px',
        }}
      >
        <Text
          style={{
            ...styles.claimsPreviewMemoTextBold,
            color: 'transparent',
          }}
        >
          No Text
        </Text>
        <Text style={styles.claimsPreviewMemoText}>
          {getText('title-258', props.user.settings)}:
        </Text>
        {atLeastOneTreeHasReducedAmounts(props.currentSnapshot) && (
          <Text style={styles.claimsPreviewMemoText}>
            {getText('title-259', props.user.settings)}:
          </Text>
        )}
        {atLeastOneTreeHasExternalValue(props.currentSnapshot) && (
          <Text style={styles.claimsPreviewMemoText}>
            {getText('title-64', props.user.settings)}:
          </Text>
        )}
      </View>
      <View
        style={{
          ...styles.claimsPreviewMemoColumn,
          height:
            !atLeastOneTreeHasReducedAmounts(props.currentSnapshot) &&
            !atLeastOneTreeHasExternalValue(props.currentSnapshot)
              ? '30px'
              : !atLeastOneTreeHasReducedAmounts(props.currentSnapshot) ||
                !atLeastOneTreeHasExternalValue(props.currentSnapshot)
              ? '45px'
              : '60px',
        }}
      >
        <Text style={styles.claimsPreviewMemoTextBold}>
          {removeUnderscore(props.currentSnapshot.partyFormatOwn)}
        </Text>
        <View style={styles.claimsPreviewMemoImageContainer}>
          <Image
            src={props.krogerusColors ? krogerusClientImg : clientImg}
            style={styles.claimsPreviewMemoImage1Client}
          />
        </View>
        {atLeastOneTreeHasReducedAmounts(props.currentSnapshot) && (
          <View style={styles.claimsPreviewMemoImageContainer}>
            <Image
              src={
                props.krogerusColors ? krogerusReduceClientImg : reduceClientImg
              }
              style={styles.claimsPreviewMemoImage2}
            />
          </View>
        )}
        {atLeastOneTreeHasExternalValue(props.currentSnapshot) && (
          <View style={styles.claimsPreviewMemoImageContainer}>
            <Image
              src={
                props.krogerusColors
                  ? krogerusExternalClientImg
                  : externalClientImg
              }
              style={styles.claimsPreviewMemoImage3Client}
            />
          </View>
        )}
      </View>
      <View
        style={{
          ...styles.claimsPreviewMemoColumn,
          height:
            !atLeastOneTreeHasReducedAmounts(props.currentSnapshot) &&
            !atLeastOneTreeHasExternalValue(props.currentSnapshot)
              ? '30px'
              : !atLeastOneTreeHasReducedAmounts(props.currentSnapshot) ||
                !atLeastOneTreeHasExternalValue(props.currentSnapshot)
              ? '45px'
              : '60px',
        }}
      >
        <Text style={styles.claimsPreviewMemoTextBold}>
          {removeUnderscore(props.currentSnapshot.partyFormatOther)}
        </Text>
        <View style={styles.claimsPreviewMemoImageContainer}>
          <Image
            src={
              props.krogerusColors ? krogerusOpposingPartyImg : opposingPartyImg
            }
            style={styles.claimsPreviewMemoImage1}
          />
        </View>
        {atLeastOneTreeHasReducedAmounts(props.currentSnapshot) && (
          <View style={styles.claimsPreviewMemoImageContainer}>
            <Image
              src={
                props.krogerusColors
                  ? krogerusReduceOpposingPartyImg
                  : reduceOpposingPartyImg
              }
              style={styles.claimsPreviewMemoImage2}
            />
          </View>
        )}
        {atLeastOneTreeHasExternalValue(props.currentSnapshot) && (
          <View style={styles.claimsPreviewMemoImageContainer}>
            <Image
              src={
                props.krogerusColors
                  ? krogerusExternalOpposingPartyImg
                  : externalOpposingPartyImg
              }
              style={styles.claimsPreviewMemoImage3}
            />
          </View>
        )}
      </View>
    </View>
  )
}

export default ClaimsLegendDocument

import { cloneDeep } from 'lodash'
import {
  ClaimType,
  FeaturesFromBackend,
  IdsOfClaims,
  IdsOfAdvanced,
  IdsOfLegalCosts,
  IdsOfLegalCostsDistribution,
  IdsOfProceedings,
  RequestError,
  SnapshotStatus,
  snapshotStatusFromString,
  ToolTabs,
  UserRole,
  InterestViewOption,
} from '../models/enums'
import {
  Features,
  HandlingErrorType,
  ResultsObject,
} from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { DateTime } from 'luxon'
import { TreeClaim } from '../models/treeModels/treeClaim'
import englishTexts from '../resources/texts/englishTexts.json'
import { createLogs } from './requests'
import {
  ClaimId,
  EventId,
  NodeId,
  TreeId,
} from '../models/treeModels/treeTypes'
import { v4 as uuid } from 'uuid'
import { roundTo2Decimals } from '../Modules/DisputeModules/AppFunctions'
import { User } from '../models/user'
import { calculateNumberOfScenarios } from './twoParentsFunctionsForNumberOfScenarios'
import { TREE_CALCULATION_TIMER } from './constants'
import { snapshotHasEnforcementRisk } from './reportFunctions'
import { createFreemiumLogs } from '../freemiumServices/freemiumRequests'
import { findIndependentClaimedAndWeightedValues } from './claimFunctions'

export const isDevEnv = () => process.env.REACT_APP_ENV === 'dev'
export const isStagingEnv = () => process.env.REACT_APP_ENV === 'staging'
export const isTestEnv = () => process.env.REACT_APP_ENV === 'test'

export const dateToString = (date: Date | undefined): string | undefined =>
  date!.toISOString().split('T')[0]

export const dateFromString = (date: string | undefined): Date | undefined => {
  if (!date || date === '') {
    return undefined
  }

  return new Date(date)
}

export const createIdentityFromDb = (item: any) => {
  return {
    caseId: item.groupid,
    caseName: item.group_name,
    scenarioId: item.caseid,
    scenarioName: item.name,
    snapshotId: item.snapshotid,
    snapshotStatus: item.job_status
      ? snapshotStatusFromString(item.job_status)
      : SnapshotStatus.None,
    snapshotProgress: item.job_progress < 5 ? 5 : item.job_progress,
    results: item.result_data ? item.result_data : undefined,
    hasReport: item.has_report,
  }
}

export const enumToVisualString = (value: any) =>
  value.toString().replace('_', ' ')

export const calculateExpectedResults = (currentSnapshot: ScenarioSnapshot) => {
  let expectedResults = currentSnapshot.claims.length > 0 ? 1 : 0
  for (let i = 0; i < currentSnapshot.claims.length; i++) {
    if (currentSnapshot.claims[i].type === ClaimType.tree) {
      let treeDetails = (currentSnapshot.claims[i] as TreeClaim).treeDetails

      if (
        (currentSnapshot.claims[i] as TreeClaim).numOfTreeScenarios ===
        undefined
      ) {
        let startTime = performance.now()

        let numberOfScenarios = calculateNumberOfScenarios(
          treeDetails,
          startTime,
          TREE_CALCULATION_TIMER,
        )

        if (typeof numberOfScenarios === 'string') {
          numberOfScenarios = 45000
        }

        ;(currentSnapshot.claims[i] as TreeClaim).numOfTreeScenarios =
          numberOfScenarios
      }
      expectedResults *= (currentSnapshot.claims[i] as TreeClaim)
        .numOfTreeScenarios
    } else {
      let multiplier = 0

      for (
        let j = 0;
        j <
        (currentSnapshot.claims[i] as IndependentClaim).quantumScenarios.length;
        j++
      ) {
        if (
          (currentSnapshot.claims[i] as IndependentClaim).quantumScenarios[j]
            .probability > 0
        ) {
          multiplier++
        }
      }
      if (multiplier > 0) {
        expectedResults *= multiplier
      }
    }
  }

  if (currentSnapshot.probSecondTrial && currentSnapshot.probSecondTrial > 0) {
    expectedResults *= 2
  }
  if (snapshotHasEnforcementRisk(currentSnapshot)) {
    expectedResults *= 2
  }

  return expectedResults
}

export const isMac = () => {
  return window.navigator.userAgent.toUpperCase().includes('MAC')
}

export function deepCloneObject(object: any): any {
  return cloneDeep(object)
}

export function removeUnderscore(word: string) {
  return word.replaceAll('_', ' ')
}

export function addUnderscore(word: string | undefined) {
  return word ? word.replaceAll(' ', '_') : ''
}

export function connectIdWithTab(id: string | string[]) {
  if (typeof id !== 'string') {
    id = id[0]
  }

  let tab = ToolTabs.proceedings
  let isIdInClaims = Object.keys(IdsOfClaims).filter((key) => id.includes(key))

  let isIdInLegalCostsDistribution = Object.keys(
    IdsOfLegalCostsDistribution,
  ).filter((key) => id.includes(key))

  switch (true) {
    case id in IdsOfProceedings:
      tab = ToolTabs.proceedings
      break
    case isIdInClaims.length > 0:
      tab = ToolTabs.claims
      break
    case id in IdsOfLegalCosts:
      tab = ToolTabs.legalCosts
      break
    case isIdInLegalCostsDistribution.length > 0:
      tab = ToolTabs.legalCostsDistribution
      break
    case id in IdsOfAdvanced:
      tab = ToolTabs.advanced
      break
    default:
      break
  }

  return tab
}

export function openChat() {
  //noUse
}

export function replaceDotswithExclamation(word: string) {
  return word.replaceAll('.', '!')
}

export function roundNumberTo(number: number, roundingDigits: number) {
  return (
    Math.round((number + Number.EPSILON) * 10 ** roundingDigits) /
    10 ** roundingDigits
  )
}
export function floorNumberTo(number: number, roundingDigits: number) {
  return (
    Math.floor((number + Number.EPSILON) * 10 ** roundingDigits) /
    10 ** roundingDigits
  )
}

export function findHandlingErrorState(
  res: any,
  handlingErrors: HandlingErrorType,
  errorInCall: string,
  fatal?: boolean,
) {
  if ('errorCode' in res) {
    if (res.errorCode === RequestError.NOT_EXIST) {
      console.log(`not exist in ${errorInCall}`)
      console.log(res)
      //Mixpanel 133
      createLogs('Handling Error', {
        'Error Type': `not exist in ${errorInCall}`,
      })
      return { ...handlingErrors, not_exist: true }
    } else if (fatal) {
      console.log(`fatal in ${errorInCall}`)
      console.log(res)
      //Mixpanel 134
      createLogs('Handling Error', { 'Error Type': `fatal in ${errorInCall}` })

      return { ...handlingErrors, fatal: true }
    } else if (res.errorCode === RequestError.UNAUTHORIZED) {
      console.log(`unauthorized in ${errorInCall}`)
      console.log(res)
      //Mixpanel 135
      createLogs('Handling Error', {
        'Error Type': `unauthorized in ${errorInCall}`,
      })

      return { ...handlingErrors, unauthorized: true }
    } else if (res.errorCode === RequestError.FORBIDDEN) {
      console.log(`forbidden in ${errorInCall}`)
      console.log(res)
      //Mixpanel 136
      createLogs('Handling Error', {
        'Error Type': `forbidden in ${errorInCall}`,
      })

      return { ...handlingErrors, forbidden: true }
    } else if (res.errorCode === RequestError.BAD_REQUEST) {
      console.log(`bad request in ${errorInCall}`)
      console.log(res)
      //Mixpanel 137
      createLogs('Handling Error', {
        'Error Type': `bad request in ${errorInCall}`,
      })

      return { ...handlingErrors, bad_request: true }
    } else if (res.errorCode === RequestError.SERVER_ERROR) {
      console.log(`server error in ${errorInCall}`)
      console.log(res)
      //Mixpanel 138
      createLogs('Handling Error', {
        'Error Type': `server error in ${errorInCall}`,
      })

      return { ...handlingErrors, server_error: true }
    } else if (res.errorCode === RequestError.TIMEOUT) {
      console.log(`timeout in ${errorInCall}`)
      console.log(res)
      //Mixpanel 139
      createLogs('Handling Error', {
        'Error Type': `timeout in ${errorInCall}`,
      })

      return { ...handlingErrors, timeout: true }
    } else if (res.errorCode === RequestError.WRONG_REDUCED_DATES) {
      console.log(
        `wrong interest dates for reduced awarded amounts in ${errorInCall}`,
      )
      console.log(res)
      //Mixpanel 186
      createLogs('Handling Error', {
        'Error Type': `wrong interest dates for reduced awarded amounts in ${errorInCall}`,
      })

      return { ...handlingErrors, wrong_reduced_dates: true }
    }
    return handlingErrors
  }
  return handlingErrors
}

export function resultsFontSize(word: string, color?: string) {
  if (word.length > 24) {
    return { fontSize: '9px', color: color }
  } else if (word.length > 23) {
    return { fontSize: '10px', color: color }
  } else if (word.length > 22) {
    return { fontSize: '11px', color: color }
  } else if (word.length > 20) {
    return { fontSize: '12px', color: color }
  } else if (word.length > 18) {
    return { fontSize: '13px', color: color }
  } else if (word.length > 16) {
    return { fontSize: '14px', color: color }
  } else {
    return { color: color }
  }
}

export function treeAmountFontSize(word: string) {
  if (word.length > 24) {
    return { fontSize: '6px' }
  } else if (word.length > 23) {
    return { fontSize: '7px' }
  } else if (word.length > 22) {
    return { fontSize: '8px' }
  } else if (word.length > 20) {
    return { fontSize: '9px' }
  } else if (word.length > 16) {
    return { fontSize: '10px' }
  } else if (word.length > 15) {
    return { fontSize: '11px' }
  } else if (word.length > 14) {
    return { fontSize: '12px' }
  } else if (word.length > 12) {
    return { fontSize: '13px' }
  } else if (word.length > 11) {
    return { fontSize: '15px' }
  } else {
    return undefined
  }
}

export function claimTileFontSize(word: string) {
  if (word.length > 25) {
    return { fontSize: '7px' }
  } else if (word.length > 24) {
    return { fontSize: '8px' }
  } else if (word.length > 23) {
    return { fontSize: '9px' }
  } else if (word.length > 21) {
    return { fontSize: '10px' }
  } else if (word.length > 18) {
    return { fontSize: '11px' }
  } else if (word.length > 17) {
    return { fontSize: '12px' }
  } else if (word.length > 16) {
    return { fontSize: '13px' }
  } else if (word.length > 15) {
    return { fontSize: '14px' }
  } else if (word.length > 14) {
    return { fontSize: '15px' }
  } else {
    return undefined
  }
}
export function treeTableFontSize(word: string) {
  if (word.length > 24) {
    return { fontSize: '8px' }
  } else if (word.length > 23) {
    return { fontSize: '9px' }
  } else if (word.length > 22) {
    return { fontSize: '10px' }
  } else if (word.length > 20) {
    return { fontSize: '11px' }
  } else if (word.length > 17) {
    return { fontSize: '12px' }
  } else if (word.length > 15) {
    return { fontSize: '13px' }
  } else {
    return { fontSize: '14px' }
  }
}

export function transformDateToString(date: Date) {
  let dateString = DateTime.fromJSDate(date).toFormat("yyyy'-'MM'-'dd'")
  if (dateString && dateString !== 'Invalid DateTime') {
    return dateString
  }

  return undefined
}

export function hasWarningMessage(res: any, message: string) {
  if (
    res.data &&
    res.data.result_data &&
    res.data.result_data.warning_messages
  ) {
    for (let messageText of res.data.result_data.warning_messages) {
      if (messageText === message) {
        return true
      }
    }
  }
  return false
}

export function isInViewport(element: any) {
  const rect = element.getBoundingClientRect()
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

export function getUserRoleDescription(role: UserRole) {
  switch (role) {
    case UserRole.APP_VIEWER:
      //data-textattribute = 'description-101'
      return 'description-101'
    case UserRole.SIMPLE_USER:
      //data-textattribute = 'description-102'
      return 'description-102'
    case UserRole.COMPANY_ADMIN:
      //data-textattribute = 'description-103'
      return 'description-103'
    case UserRole.GLOBAL_ADMIN:
      //data-textattribute = 'description-104'
      return 'description-104'
    case UserRole.EPEROTO_ADMIN:
      return 'description-104'
  }
}

export function getFullUserRolesAndDescriptions(): {
  [role: string]: keyof typeof englishTexts
} {
  return {
    Viewer:
      //data-textattribute = 'description-101'
      'description-101',
    'Simple User':
      //data-textattribute = 'description-102'
      'description-102',
    'Organisation Admin':
      //data-textattribute = 'description-103'
      'description-103',
    'Organisation Global Admin':
      //data-textattribute = 'description-104'
      'description-104',
  }
}

export function getFullUserRolesAndDescriptionsTetxAttributes(): [
  `title-${number}`,
  `description-${number}`,
][] {
  return [
    ['title-153', 'description-101'],
    ['title-154', 'description-102'],
    ['title-155', 'description-103'],
    ['title-156', 'description-104'],
  ]
}

export function snapshotHasTree(snapshot: ScenarioSnapshot) {
  for (let claim of snapshot.claims) {
    if (claim.type === 'tree') {
      return true
    }
  }
  return false
}

export function generateId(
  prefix: 'node' | 'event' | 'claim' | 'tree',
): NodeId | EventId | ClaimId | TreeId {
  return `${prefix}${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`
}

export const isJSON = (string: string) => {
  try {
    JSON.parse(string)
    return true
  } catch (error) {
    return false
  }
}
export function getMaxForProb(
  probabilites: number[] | undefined,
  index: number,
  multiply?: boolean,
) {
  if (probabilites === undefined) {
    return 0
  }

  let max = multiply ? 1 : 100
  for (let i = 0; i < index; i++) {
    max -= probabilites[i]
  }

  return multiply ? roundTo2Decimals(max) * 100 : max
}

export function updateFeaturesFromBackend(
  features: Features,
  featuresFromBackend: Array<FeaturesFromBackend>,
) {
  if (featuresFromBackend.includes(FeaturesFromBackend.share_case)) {
    features.share_case = true
  }
  if (featuresFromBackend.includes(FeaturesFromBackend.use_logs)) {
    features.use_logs = true
  }
  if (featuresFromBackend.includes(FeaturesFromBackend.use_recordings)) {
    features.use_recordings = true
  }
  if (featuresFromBackend.includes(FeaturesFromBackend.use_sms)) {
    features.use_sms = true
  }
  if (featuresFromBackend.includes(FeaturesFromBackend.use_statistical_model)) {
    features.use_statistical_model = true
  }
  if (featuresFromBackend.includes(FeaturesFromBackend.use_whitelabel)) {
    features.use_whitelabel = true
  }
  return features
}

export function userIsAdmin(user: User) {
  return user.email.includes('eperoto.com')
}

export const getClientDomain = () => {
  const startIndex = window.location.href.indexOf('//') + 2
  const endIndex = window.location.href.indexOf('/', startIndex)
  return window.location.href.substring(startIndex, endIndex)
}

export const logActivity = (
  isFreemium: boolean,
  eventName: string,
  props?: any,
) => {
  if (isFreemium) {
    createFreemiumLogs(eventName, props)
  } else {
    createLogs(eventName, props)
  }
}

export const getRandomInt = (min: number, max: number) => {
  return Math.floor(min + Math.random() * (max - min + 1))
}

export function calculateZOPA(resultsFromBackend: ResultsObject) {
  const leftLimitForClient =
    resultsFromBackend.settlement_amount ?? resultsFromBackend.value_of_claim
  const leftLimitForOpposingParty =
    -resultsFromBackend.reversed!.minmax.max.financial_outcome
  const rightLimitForOpposingParty =
    -resultsFromBackend.reversed!.settlement_amount

  const clientLeftBiggerThanOpposingRight =
    leftLimitForClient > rightLimitForOpposingParty

  let zopaHighAmount
  let zopaLowAmount

  if (clientLeftBiggerThanOpposingRight) {
    zopaLowAmount = leftLimitForOpposingParty
    zopaHighAmount = leftLimitForClient
  } else {
    zopaLowAmount = leftLimitForClient
    zopaHighAmount = rightLimitForOpposingParty
  }

  if (clientLeftBiggerThanOpposingRight) {
    return 'noZOPA'
  } else {
    if (zopaHighAmount > resultsFromBackend.minmax.max.financial_outcome) {
      zopaHighAmount = resultsFromBackend.minmax.max.financial_outcome
    }
    if (
      zopaLowAmount < -resultsFromBackend.reversed!.minmax.max.financial_outcome
    ) {
      zopaLowAmount = -resultsFromBackend.reversed!.minmax.max.financial_outcome
    }
  }

  return [zopaLowAmount, zopaHighAmount]
}

export function calculateWeightedValues(currentSnapshot: ScenarioSnapshot) {
  let res = 0
  for (let claim of currentSnapshot.claims) {
    if (claim.type !== ClaimType.tree) {
      const independentClaimedAndWeightedValues =
        findIndependentClaimedAndWeightedValues(
          claim,
          currentSnapshot.firstTrialDate,
          currentSnapshot.secondTrialDate,
        )

      switch (currentSnapshot.interestViewOption) {
        case InterestViewOption.noInterest:
          res += independentClaimedAndWeightedValues.weightedValue
          break
        case InterestViewOption.interest1st:
          res += independentClaimedAndWeightedValues.weightedValueInterest1st
          break
        case InterestViewOption.interest2nd:
          res += independentClaimedAndWeightedValues.weightedValueInterest2nd
          break
        default:
          break
      }
    } else {
      switch (currentSnapshot.interestViewOption) {
        case InterestViewOption.noInterest:
          res += (claim as TreeClaim).treeWeightedValue
            ? (claim as TreeClaim).treeWeightedValue!
            : 0
          break
        case InterestViewOption.interest1st:
          res += (claim as TreeClaim).treeWeightedValueInterest1st
            ? (claim as TreeClaim).treeWeightedValueInterest1st!
            : 0
          break
        case InterestViewOption.interest2nd:
          res += (claim as TreeClaim).treeWeightedValueInterest2nd
            ? (claim as TreeClaim).treeWeightedValueInterest2nd!
            : 0
          break
        default:
          break
      }
    }
  }

  return res
}

export function findClaimedAmountOfClaim(
  currentSnapshot: ScenarioSnapshot,
  claimIndex: number,
) {
  let claim = currentSnapshot.claims[claimIndex]

  if (claim.type === ClaimType.tree) {
    const treeClaim = claim as TreeClaim
    switch (currentSnapshot.interestViewOption) {
      case InterestViewOption.noInterest:
        return treeClaim.totalClaimedAmount ? treeClaim.totalClaimedAmount : 0
      case InterestViewOption.interest1st:
        return treeClaim.totalClaimedAmountInterest1st
          ? treeClaim.totalClaimedAmountInterest1st
          : 0
      case InterestViewOption.interest2nd:
        return treeClaim.totalClaimedAmountInterest2nd
          ? treeClaim.totalClaimedAmountInterest2nd
          : 0
      default:
        return 0
    }
  } else {
    const independentClaim = claim as IndependentClaim
    const independentClaimedAndWeightedValues =
      findIndependentClaimedAndWeightedValues(
        independentClaim,
        currentSnapshot.firstTrialDate,
        currentSnapshot.secondTrialDate,
      )
    if (independentClaim.type === ClaimType.claim) {
      switch (currentSnapshot.interestViewOption) {
        case InterestViewOption.noInterest:
          return independentClaimedAndWeightedValues.totalClaimedAmount
            ? independentClaimedAndWeightedValues.totalClaimedAmount
            : 0
        case InterestViewOption.interest1st:
          return independentClaimedAndWeightedValues.totalClaimedAmountInterest1st
            ? independentClaimedAndWeightedValues.totalClaimedAmountInterest1st
            : 0
        case InterestViewOption.interest2nd:
          return independentClaimedAndWeightedValues.totalClaimedAmountInterest2nd
            ? independentClaimedAndWeightedValues.totalClaimedAmountInterest2nd
            : 0
        default:
          return 0
      }
    } else {
      return 0
    }
  }
}

export function findCounterClaimedAmountOfClaim(
  currentSnapshot: ScenarioSnapshot,
  claimIndex: number,
) {
  let claim = currentSnapshot.claims[claimIndex]

  if (claim.type === ClaimType.tree) {
    const treeClaim = claim as TreeClaim
    switch (currentSnapshot.interestViewOption) {
      case InterestViewOption.noInterest:
        return treeClaim.totalCounterClaimedAmount
          ? treeClaim.totalCounterClaimedAmount
          : 0
      case InterestViewOption.interest1st:
        return treeClaim.totalCounterClaimedAmountInterest1st
          ? treeClaim.totalCounterClaimedAmountInterest1st
          : 0
      case InterestViewOption.interest2nd:
        return treeClaim.totalCounterClaimedAmountInterest2nd
          ? treeClaim.totalCounterClaimedAmountInterest2nd
          : 0
      default:
        return 0
    }
  } else {
    const independentClaim = claim as IndependentClaim
    const independentClaimedAndWeightedValues =
      findIndependentClaimedAndWeightedValues(
        independentClaim,
        currentSnapshot.firstTrialDate,
        currentSnapshot.secondTrialDate,
      )
    if (independentClaim.type === ClaimType.counterclaim) {
      switch (currentSnapshot.interestViewOption) {
        case InterestViewOption.noInterest:
          return independentClaimedAndWeightedValues.totalClaimedAmount
            ? independentClaimedAndWeightedValues.totalClaimedAmount
            : 0
        case InterestViewOption.interest1st:
          return independentClaimedAndWeightedValues.totalClaimedAmountInterest1st
            ? independentClaimedAndWeightedValues.totalClaimedAmountInterest1st
            : 0
        case InterestViewOption.interest2nd:
          return independentClaimedAndWeightedValues.totalClaimedAmountInterest2nd
            ? independentClaimedAndWeightedValues.totalClaimedAmountInterest2nd
            : 0
        default:
          return 0
      }
    } else {
      return 0
    }
  }
}

export function findWeightedValueOfClaim(
  currentSnapshot: ScenarioSnapshot,
  claimIndex: number,
) {
  let claim = currentSnapshot.claims[claimIndex]

  if (claim.type === ClaimType.tree) {
    const treeClaim = claim as TreeClaim
    switch (currentSnapshot.interestViewOption) {
      case InterestViewOption.noInterest:
        return treeClaim.treeWeightedValue ? treeClaim.treeWeightedValue : 0
      case InterestViewOption.interest1st:
        return treeClaim.treeWeightedValueInterest1st
          ? treeClaim.treeWeightedValueInterest1st
          : 0
      case InterestViewOption.interest2nd:
        return treeClaim.treeWeightedValueInterest2nd
          ? treeClaim.treeWeightedValueInterest2nd
          : 0
      default:
        return 0
    }
  } else {
    const independentClaim = claim as IndependentClaim
    const independentClaimedAndWeightedValues =
      findIndependentClaimedAndWeightedValues(
        independentClaim,
        currentSnapshot.firstTrialDate,
        currentSnapshot.secondTrialDate,
      )

    switch (currentSnapshot.interestViewOption) {
      case InterestViewOption.noInterest:
        return independentClaimedAndWeightedValues.weightedValue
          ? independentClaimedAndWeightedValues.weightedValue
          : 0
      case InterestViewOption.interest1st:
        return independentClaimedAndWeightedValues.weightedValueInterest1st
          ? independentClaimedAndWeightedValues.weightedValueInterest1st
          : 0
      case InterestViewOption.interest2nd:
        return independentClaimedAndWeightedValues.weightedValueInterest2nd
          ? independentClaimedAndWeightedValues.weightedValueInterest2nd
          : 0
      default:
        return 0
    }
  }
}

import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { stringAndRoundTo2Decimals } from '../../../../../../../../Modules/DisputeModules/AppFunctions'
import { resultsFontSize } from '../../../../../../../../services/commonFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { getText } from '../../../../../../../../services/textFunctions'
import { OutcomeBreakdown } from '../../../../../../../../models/generalTypes'
import { ClaimType } from '../../../../../../../../models/enums'
import { TreeClaim } from '../../../../../../../../models/treeModels/treeClaim'
import {
  calculateClaimScenarioOrEventInterest,
  claimOrEventHasInterest,
  findClaimScenarioOrEventAwardedAmount,
  findClaimOrEventInterestDate,
  findClaimOrEventInterestRate,
} from '../../../../../../../../services/resultsFunctions'
import { convertDateTimeToLocal } from '../../../../../../../../services/dateFunctions'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
  KROGERUS_OPPOSING_PARTY_COLOR,
  OPPOSING_PARTY_COLOR,
} from '../../../../../../../../services/constants'
import { krogerusColorsState } from '../../../../../../../../states/KrogerusColorsState'

type Props = {
  id: number
  sortedDataForTable: any
  currentSnapshot: ScenarioSnapshot
  resultsViewParty: 'client' | 'opposing'
}

export default function InterestAwarded(props: Props) {
  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
{
  claimIndex: number
  scenarioIndex: number
  nodeId: NodeId
  eventId: EventId
}    
    
    */

  const breakdown: OutcomeBreakdown[] = props.sortedDataForTable[props.id][16]
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  return (
    <>
      <div className="hoverBackground" data-openreplay-obscured>
        <div className="pathElementContainer">
          {breakdown.map((item, index) => {
            return (
              <div
                className="pathElement"
                style={
                  (findClaimScenarioOrEventAwardedAmount(
                    props.currentSnapshot.claims[item.claimIndex],
                    item,
                  ) >= 0 &&
                    props.resultsViewParty === 'client') ||
                  (findClaimScenarioOrEventAwardedAmount(
                    props.currentSnapshot.claims[item.claimIndex],
                    item,
                  ) <= 0 &&
                    props.resultsViewParty === 'opposing')
                    ? {
                        backgroundColor: krogerusColors
                          ? KROGERUS_CLIENT_COLOR
                          : CLIENT_COLOR,
                      }
                    : {
                        backgroundColor: krogerusColors
                          ? KROGERUS_OPPOSING_PARTY_COLOR
                          : OPPOSING_PARTY_COLOR,
                      }
                }
                key={`breakdownlist${index}`}
              >
                <p
                  className="claimTitle"
                  style={
                    (findClaimScenarioOrEventAwardedAmount(
                      props.currentSnapshot.claims[item.claimIndex],
                      item,
                    ) >= 0 &&
                      props.resultsViewParty === 'client') ||
                    (findClaimScenarioOrEventAwardedAmount(
                      props.currentSnapshot.claims[item.claimIndex],
                      item,
                    ) <= 0 &&
                      props.resultsViewParty === 'opposing')
                      ? { color: 'white' }
                      : undefined
                  }
                >
                  {props.currentSnapshot.claims[item.claimIndex].name}
                </p>

                {props.currentSnapshot.claims[item.claimIndex].type ===
                ClaimType.tree ? (
                  <>
                    <div className="scenarioBreakdownContainer">
                      <p
                        className="treeQuestions"
                        style={
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) >= 0 &&
                            props.resultsViewParty === 'client') ||
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) <= 0 &&
                            props.resultsViewParty === 'opposing')
                            ? { color: 'white' }
                            : undefined
                        }
                      >
                        {(
                          props.currentSnapshot.claims[
                            item.claimIndex
                          ] as TreeClaim
                        ).treeDetails.nodes[item.nodeId].nodeTitle !== ''
                          ? (
                              props.currentSnapshot.claims[
                                item.claimIndex
                              ] as TreeClaim
                            ).treeDetails.nodes[item.nodeId].nodeTitle
                          : 'No Node Name'}
                      </p>
                    </div>
                    <div className="scenarioBreakdownContainer">
                      <p
                        className="treeQuestions"
                        style={
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) >= 0 &&
                            props.resultsViewParty === 'client') ||
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) <= 0 &&
                            props.resultsViewParty === 'opposing')
                            ? { color: 'white' }
                            : undefined
                        }
                      >
                        {(
                          props.currentSnapshot.claims[
                            item.claimIndex
                          ] as TreeClaim
                        ).treeDetails.events[item.eventId].eventDetails
                          .eventTitle !== ''
                          ? (
                              props.currentSnapshot.claims[
                                item.claimIndex
                              ] as TreeClaim
                            ).treeDetails.events[item.eventId].eventDetails
                              .eventTitle
                          : 'No Event Name'}
                      </p>
                    </div>
                  </>
                ) : null}
                <div className="scenarioBreakdownContainer">
                  <p
                    className="scenarioTitle"
                    id="detailedResultsHoverComponentTitle-amountAwarded"
                    data-textattribute="title-100"
                    style={
                      (findClaimScenarioOrEventAwardedAmount(
                        props.currentSnapshot.claims[item.claimIndex],
                        item,
                      ) >= 0 &&
                        props.resultsViewParty === 'client') ||
                      (findClaimScenarioOrEventAwardedAmount(
                        props.currentSnapshot.claims[item.claimIndex],
                        item,
                      ) <= 0 &&
                        props.resultsViewParty === 'opposing')
                        ? { color: 'white' }
                        : undefined
                    }
                  >
                    {getText('title-100', user.settings)}
                  </p>
                  <p
                    className="amount"
                    style={resultsFontSize(
                      stringAndRoundTo2Decimals(
                        findClaimScenarioOrEventAwardedAmount(
                          props.currentSnapshot.claims[item.claimIndex],
                          item,
                        ) ?? 0,
                        user.settings,
                      ) +
                        ' ' +
                        props.currentSnapshot.currency,
                      (findClaimScenarioOrEventAwardedAmount(
                        props.currentSnapshot.claims[item.claimIndex],
                        item,
                      ) >= 0 &&
                        props.resultsViewParty === 'client') ||
                        (findClaimScenarioOrEventAwardedAmount(
                          props.currentSnapshot.claims[item.claimIndex],
                          item,
                        ) <= 0 &&
                          props.resultsViewParty === 'opposing')
                        ? 'white'
                        : undefined,
                    )}
                  >
                    {stringAndRoundTo2Decimals(
                      Math.abs(
                        findClaimScenarioOrEventAwardedAmount(
                          props.currentSnapshot.claims[item.claimIndex],
                          item,
                        ) ?? 0,
                      ),
                      user.settings,
                    )}{' '}
                    {props.currentSnapshot.currency}
                  </p>
                </div>
                {claimOrEventHasInterest(
                  props.currentSnapshot.claims[item.claimIndex],
                  item,
                ) ? (
                  <>
                    <div className="scenarioBreakdownContainer">
                      <p
                        className="scenarioTitle"
                        id="detailedResultsHoverComponentTitle-interestRate"
                        data-textattribute="title-101"
                        style={
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) >= 0 &&
                            props.resultsViewParty === 'client') ||
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) <= 0 &&
                            props.resultsViewParty === 'opposing')
                            ? { color: 'white' }
                            : undefined
                        }
                      >
                        {getText('title-101', user.settings)}
                      </p>
                      <p
                        className="amount"
                        style={
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) >= 0 &&
                            props.resultsViewParty === 'client') ||
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) <= 0 &&
                            props.resultsViewParty === 'opposing')
                            ? { color: 'white' }
                            : undefined
                        }
                      >
                        {Math.abs(
                          findClaimOrEventInterestRate(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ),
                        )}{' '}
                        %
                      </p>
                    </div>
                    <div className="scenarioBreakdownContainer">
                      <p
                        className="scenarioTitle"
                        id="detailedResultsHoverComponentTitle-interestDate"
                        data-textattribute="title-102"
                        style={
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) >= 0 &&
                            props.resultsViewParty === 'client') ||
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) <= 0 &&
                            props.resultsViewParty === 'opposing')
                            ? { color: 'white' }
                            : undefined
                        }
                      >
                        {getText('title-102', user.settings)}
                      </p>
                      <p
                        className="amount"
                        style={
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) >= 0 &&
                            props.resultsViewParty === 'client') ||
                          (findClaimScenarioOrEventAwardedAmount(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ) <= 0 &&
                            props.resultsViewParty === 'opposing')
                            ? { color: 'white' }
                            : undefined
                        }
                      >
                        {convertDateTimeToLocal(
                          findClaimOrEventInterestDate(
                            props.currentSnapshot.claims[item.claimIndex],
                            item,
                          ),
                          user.settings,
                          true,
                        )}
                      </p>
                    </div>
                  </>
                ) : null}

                <div className="scenarioBreakdownContainer">
                  <p
                    className="scenarioTitle"
                    id="detailedResultsHoverComponentTitle-interest"
                    data-textattribute="title-103"
                    style={
                      (findClaimScenarioOrEventAwardedAmount(
                        props.currentSnapshot.claims[item.claimIndex],
                        item,
                      ) >= 0 &&
                        props.resultsViewParty === 'client') ||
                      (findClaimScenarioOrEventAwardedAmount(
                        props.currentSnapshot.claims[item.claimIndex],
                        item,
                      ) <= 0 &&
                        props.resultsViewParty === 'opposing')
                        ? { color: 'white' }
                        : undefined
                    }
                  >
                    {getText('title-103', user.settings)}
                  </p>
                  <p
                    className="amount"
                    style={resultsFontSize(
                      stringAndRoundTo2Decimals(
                        calculateClaimScenarioOrEventInterest(
                          props.currentSnapshot.claims[item.claimIndex],
                          item,
                          props.sortedDataForTable[props.id][1] === 0 ||
                            props.sortedDataForTable[props.id][1] === 2
                            ? props.currentSnapshot.firstTrialDate!
                            : props.currentSnapshot.secondTrialDate!,
                        ),

                        user.settings,
                      ) +
                        ' ' +
                        props.currentSnapshot.currency,
                      (findClaimScenarioOrEventAwardedAmount(
                        props.currentSnapshot.claims[item.claimIndex],
                        item,
                      ) >= 0 &&
                        props.resultsViewParty === 'client') ||
                        (findClaimScenarioOrEventAwardedAmount(
                          props.currentSnapshot.claims[item.claimIndex],
                          item,
                        ) <= 0 &&
                          props.resultsViewParty === 'opposing')
                        ? 'white'
                        : undefined,
                    )}
                  >
                    {stringAndRoundTo2Decimals(
                      Math.abs(
                        calculateClaimScenarioOrEventInterest(
                          props.currentSnapshot.claims[item.claimIndex],
                          item,
                          props.sortedDataForTable[props.id][1] === 0 ||
                            props.sortedDataForTable[props.id][1] === 2
                            ? props.currentSnapshot.firstTrialDate!
                            : props.currentSnapshot.secondTrialDate!,
                        ),
                      ),

                      user.settings,
                    )}{' '}
                    {props.currentSnapshot.currency}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

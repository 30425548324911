import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioIdentityState } from '../../states/ScenarioIdentityState'
import HeaderPath from './headerComponents/headerPath/HeaderPath'
import HeaderUndoRedo from './headerComponents/HeaderUndoRedo'
import HeaderSave from './headerComponents/HeaderSave'

import { AccessRole, SnapshotStatus } from '../../models/enums'
import { savedState } from '../../states/SavedState'
import { getScenarios } from '../../services/requests'
import { Params } from '../../models/generalTypes'
import { savedReportState } from '../../states/SavedReportState'
import HandlingErrorsComponent from './headerComponents/HandlingErrorsComponent'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import { findHandlingErrorState } from '../../services/commonFunctions'
import { freemiumState } from '../../states/FreemiumState'
import { getFreemiumScenarios } from '../../freemiumServices/freemiumRequests'
import { getScenarioFromStorage } from '../../freemiumServices/freemiumServices'

type Props = {
  pageDepth: number
  saveMenuOn?: undefined | string
  setSaveMenuOn?: (param: undefined | string) => void
  handleSave?: (param: boolean) => Promise<'saved' | 'not saved'>
  handleSaveReport?: () => Promise<'report saved' | 'report not saved'>
  saving: string
  savedMessage?: string
  isSaveAsNew?: boolean
  setIsSaveAsNew?: (param: boolean) => void
  setSavedMessage?: (param: string) => void
  headerTutorialTitle?: string
  ownRole?: AccessRole
}

export default function Header(props: Props) {
  const params = useParams<Params>()

  const scenarioIdentity = useRecoilValue(scenarioIdentityState)

  const [showSaveAsAndUndo, setShowSaveAsAndUndo] = useState(true)

  const [caseName, setCaseName] = useState('')
  const [isTutorial, setIsTutorial] = useState(false)
  const savedSnapshot = useRecoilValue(savedState)
  const savedReport = useRecoilValue(savedReportState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  const [scenariosList, setScenariosList] = useState([{}])
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (props.pageDepth >= 2 && params.caseId) {
      ;(freemium.isFreemium
        ? getFreemiumScenarios(params.caseId)
        : getScenarios(params.caseId)
      ).then((res: any) => {
        if (
          'errorCode' in res &&
          (!freemium.isFreemium || res.errorCode !== 404)
        ) {
          setHandlingErrors(
            findHandlingErrorState(res, handlingErrors, 'getScenarios', true),
          )
        }

        if (
          !('errorCode' in res) ||
          (freemium.isFreemium && res.errorCode === 404)
        ) {
          if (res.data) {
            if (res.data.caseName) {
              setCaseName(res.data.caseName)
              setIsTutorial(res.data.tutorial_id !== null)
            } else {
              setCaseName(res.data[0].group_name)
              setIsTutorial(res.data[0].tutorial_id !== null)
            }
          } else {
            const scenarioStorage = getScenarioFromStorage()
            if (scenarioStorage) {
              setCaseName(scenarioStorage.caseName)
            }
            setIsTutorial(false)
          }

          let tempScenariosList = []
          for (let scenario in res.data) {
            tempScenariosList.push(res.data[scenario])
          }
          setScenariosList(tempScenariosList)
        }
      })
    }
    // eslint-disable-next-line
  }, [props.pageDepth, scenarioIdentity.scenarioId])

  useEffect(() => {
    if (
      scenarioIdentity.snapshotStatus === SnapshotStatus.CalculatingTrees ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Saving ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Queuing ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Queued ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.ProcessingReversed ||
      props.saving !== ''
    ) {
      setShowSaveAsAndUndo(false)
    } else {
      setShowSaveAsAndUndo(true)
    }
  }, [scenarioIdentity.snapshotStatus, props.saving])

  return (
    <>
      <HandlingErrorsComponent />
      <div className="headerContainer">
        <div className="leftHeader">
          <HeaderPath
            caseName={caseName || scenarioIdentity.caseName}
            isTutorial={isTutorial}
            scenarioName={scenarioIdentity.scenarioName}
            saved={savedSnapshot.saved}
            reportSaved={savedReport.saved}
            pageDepth={props.pageDepth}
            handleSave={props.handleSave!}
            handleSaveReport={props.handleSaveReport!}
            scenariosList={scenariosList}
            saving={props.saving!}
            saveMenuOn={props.saveMenuOn}
            setSaveMenuOn={props.setSaveMenuOn}
            setSavedMessage={props.setSavedMessage}
            headerTutorialTitle={props.headerTutorialTitle}
          />
        </div>
        <div className="rightHeader">
          {props.pageDepth === 3 && scenarioIdentity.caseId !== '' ? (
            <>
              {showSaveAsAndUndo ? (
                <HeaderUndoRedo ownRole={props.ownRole!} />
              ) : null}
              <HeaderSave
                ownRole={props.ownRole!}
                saving={props.saving!}
                handleSave={props.handleSave!}
                showSaveAsAndUndo={showSaveAsAndUndo}
                savedMessage={props.savedMessage}
                isSaveAsNew={props.isSaveAsNew!}
                setIsSaveAsNew={props.setIsSaveAsNew!}
                savedState={savedSnapshot.saved}
                hideSave={freemium.isFreemium}
              />
            </>
          ) : props.pageDepth === 3.1 ? (
            <>
              <HeaderSave
                ownRole={props.ownRole!}
                saving={props.saving!}
                handleSave={props.handleSaveReport!}
                showSaveAsAndUndo={false}
                savedMessage={props.savedMessage}
                isSaveAsNew={props.isSaveAsNew!}
                setIsSaveAsNew={props.setIsSaveAsNew!}
                savedState={savedReport.saved}
                hideSave
              />
            </>
          ) : null}
        </div>
      </div>
    </>
  )
}

import { useRecoilValue } from 'recoil'
import { AmountSignum, Currency } from '../../../../../../models/enums'
import { formattedNumToString } from '../../../../../../services/formatNum'
import { userState } from '../../../../../../states/UserState'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Props = {
  quantumAmount: number
  quantumProbability: number
  externalConsequenceSignum: AmountSignum
  externalConsequenceAmount: number
  isExternalConsequenceOn: boolean
  quantumIndex: number
  claimIndex: number
  currency: Currency
  reportWhitelabel?: ReportWhitelabel
}

export default function QuantumComponentForClaimTile(props: Props) {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  return (
    <>
      <div className={`quantumRowContainer`}>
        <div
          className="quantumAmountAndProbabilityContainer"
          id={`quantumAmountAndProbabilityContainer-${props.claimIndex}_${props.quantumIndex}`}
        >
          <p
            className={`quantumAmountText ${krogerusColors ? 'krogerus' : ''} ${
              props.quantumAmount < 0 ? 'negative' : 'positive'
            }`}
            id={`quantumAmountText-${props.claimIndex}_${props.quantumIndex}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              false,
              true,
            )}
          >
            {formattedNumToString(props.quantumAmount, user.settings, true)}{' '}
            {props.currency}
          </p>
          <p
            className="quantumProbability"
            id={`quantumProbability-${props.claimIndex}_${props.quantumIndex}`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
            )}
          >
            {props.quantumProbability}%
          </p>
        </div>
        {props.isExternalConsequenceOn && (
          <div
            className="externalConsequenceContainer"
            id={`externalConsequenceContainer-${props.claimIndex}_${props.quantumIndex}`}
          >
            {props.externalConsequenceAmount !== 0 ? (
              <p
                className={`${
                  krogerusColors ? 'krogerus' : ''
                } externalConsequence positive`}
                id={`externalConsequenceValue-${props.claimIndex}_${props.quantumIndex}`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  false,
                  true,
                )}
              >
                {formattedNumToString(
                  props.externalConsequenceAmount,
                  user.settings,
                )}{' '}
                {props.currency}
              </p>
            ) : (
              <p
                className={`externalConsequence`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                )}
              >
                -
              </p>
            )}
          </div>
        )}
      </div>
    </>
  )
}

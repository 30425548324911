import React, { useState, useEffect, useRef } from 'react'

import deleteImg from '../../../../../../resources/images/029-deleteBucketSign.svg'
import addSignImg from '../../../../../../resources/images/013-plusSignThinYellowBlue.svg'
import deleteBucket from '../../../../../../resources/images/201-deleteBucketWIthBorder.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { IndependentClaim } from '../../../../../../models/independentClaim'
import { SnapshotSelectorObject } from '../../../../../../models/generalTypes'
import {
  AccessRole,
  AmountSignum,
  UndoRedoType,
} from '../../../../../../models/enums'
import {
  deepCloneObject,
  getMaxForProb,
  logActivity,
  roundNumberTo,
} from '../../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../../services/changeGlobalSnapshot'
import { QuantumScenario } from '../../../../../../models/quantumScenario'
import {
  getAbs,
  formattedNumToString,
} from '../../../../../../services/formatNum'
import HoverComponent from '../../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import * as objects from '../../../../../../Modules/Objects/DropDownObjects'
import SwitchButton from '../../../../../../Components/Buttons/SwitchButton/SwitchButton'
import InputNum from '../../../../../../Components/Inputs/InputNum/InputNum'
import InputNumAutocomplete from '../../../../../../Components/Inputs/InputNum/InputNumAutocomplete'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'
import { freemiumState } from '../../../../../../states/FreemiumState'
import Symbol from '../../../../../../Components/symbol/Symbol'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
} from '../../../../../../services/constants'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Props = {
  claimIndex: number
  currentProbability: number
  probabilityIndex: number
  smallerWidth: boolean
  innerWidthState: number
  errors: string[]
  ownRole: AccessRole
}

export default function SingleQuantum(props: Props) {
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const [showDeleteImg, setShowDeleteImg] = useState(false)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const currentClaim = scenarioSnapshot.currentSnapshot.claims[
    props.claimIndex
  ] as IndependentClaim

  const currentQuantum = (
    scenarioSnapshot.currentSnapshot.claims[
      props.claimIndex
    ] as IndependentClaim
  ).quantumScenarios[props.probabilityIndex]

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function handleOutOfCourtSignumChange(value: string, targetId: string) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: targetId,
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'claims',
      claimIndex: props.claimIndex,
      secondaryIndex: props.probabilityIndex,
      key2: 'quantumScenarios',
      key3: 'outOfCourtSignum',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleSwitchOutOfCourtSignum() {
    let tempValue = 'get'
    if (currentQuantum.outOfCourtSignum === 'get') {
      tempValue = 'pay'
    }
    let targetId = `switchClaimOutOfCourtSignum-${props.claimIndex}_${props.probabilityIndex}`
    handleOutOfCourtSignumChange(tempValue, targetId)
  }

  function handleQuantumValueChange(
    value: number | undefined,
    targetId: string,
    key3: keyof QuantumScenario,
  ) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: targetId,
      undoRedoType: UndoRedoType.input,
      value: value,
      key: 'claims',
      claimIndex: props.claimIndex,
      secondaryIndex: props.probabilityIndex,
      key2: 'quantumScenarios',
      key3: key3,
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleRemoveSingleQuantum() {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `addClaimSingleQuantum-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: 'remove',
      key: 'claims',
      claimIndex: props.claimIndex,
      secondaryIndex: props.probabilityIndex,
      key2: 'quantumProbabilities',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    //Mixpanel 45 (All)
    logActivity(freemium.isFreemium, 'Removed quantum outcome')
  }

  return (
    <div
      className="singleQuantum"
      key={props.probabilityIndex}
      id={`singleQuantum-${props.claimIndex}_${props.probabilityIndex}`}
      style={
        !currentClaim.hasOutOfCourt && props.innerWidthState < 1230
          ? { height: '110px', width: '408px' }
          : !currentClaim.hasOutOfCourt
          ? { height: '110px', width: '100%' }
          : props.smallerWidth && props.innerWidthState < 1230
          ? { height: '184px', width: '408px' }
          : props.smallerWidth
          ? { height: '110px', width: '100%' }
          : currentClaim.hasOutOfCourt && props.innerWidthState < 1230
          ? { height: '184px', width: '511px' }
          : currentClaim.hasOutOfCourt
          ? { height: '110px', width: '100%' }
          : undefined
      }
    >
      {
        //Full success
        props.probabilityIndex === 0 ? (
          <div className="amountInputContainer fullAmountInputContainer">
            {hoverElement === 'fullSuccesHover' ? (
              <div
                className="hoverComponentContainer"
                style={{ top: '20px', left: '180px' }}
              >
                <HoverComponent
                  hoverText={getText('hover-17', user.settings)}
                  textAttribute="hover-17"
                  id={`fullSuccesHover`}
                />
              </div>
            ) : null}
            <InputNum
              label={
                currentClaim.type === 'claim'
                  ? getText('label-18', user.settings, partiesFormat)
                  : getText('label-19', user.settings, partiesFormat)
              }
              labelTextAttribute={
                currentClaim.type === 'claim' ? 'label-18' : 'label-19'
              }
              suffix={scenarioSnapshot.currentSnapshot.currency}
              boldBorder
              width={250}
              placeholderPadding="43px"
              value={
                (
                  scenarioSnapshot.currentSnapshot.claims[
                    props.claimIndex
                  ] as IndependentClaim
                ).amount ?? 0
              }
              readOnly
              maxValue={99999999999}
              maxNumberOfDecimals={3}
              borderColor={'transparent'}
              tagcolor={objects.percentagesColors[props.probabilityIndex]}
              id={`fullSuccess-${props.claimIndex}`}
              className={`prob${props.claimIndex + 1} amount`}
              localStyle={
                !currentClaim.hasOutOfCourt && props.innerWidthState < 1230
                  ? {
                      marginTop: '21px !important',
                    }
                  : {}
              }
              tabIndex={-1}
              name={`fullSuccess-${props.claimIndex}`}
            />
            <div
              className="fullSuccessCoverLayer"
              style={{ width: 270, height: 80 }}
              onClick={() => {
                setHoverElement('fullSuccesHover')
              }}
            ></div>
          </div>
        ) : (
          <div className="amountInputContainer">
            <InputNumAutocomplete
              label={
                currentClaim.type === 'claim'
                  ? getText('label-18', user.settings, partiesFormat).length >
                    31
                    ? getText('label-18', user.settings, partiesFormat)
                        .substring(0, 31)
                        .trim() + '...'
                    : getText('label-18', user.settings, partiesFormat)
                  : getText('label-19', user.settings, partiesFormat).length >
                    31
                  ? getText('label-19', user.settings, partiesFormat)
                      .substring(0, 31)
                      .trim() + '...'
                  : getText('label-19', user.settings, partiesFormat)
              }
              labelTextAttribute={
                currentClaim.type === 'claim' ? 'label-18' : 'label-19'
              }
              suffix={scenarioSnapshot.currentSnapshot.currency}
              width={250}
              alwaysShrink
              value={
                currentClaim.quantumScenarios[props.probabilityIndex].amount !==
                undefined
                  ? getAbs(
                      currentClaim.quantumScenarios[props.probabilityIndex]
                        .amount,
                    )
                  : undefined
              }
              maxValue={getAbs(currentClaim.amount)}
              maxNumberOfDecimals={3}
              className={`prob${props.claimIndex + 1} amount`}
              id={`claimAltAmount-${props.claimIndex}_${props.probabilityIndex}`}
              tabIndex={7}
              borderColor={objects.percentagesColors[props.probabilityIndex]}
              tagcolor={objects.percentagesColors[props.probabilityIndex]}
              boldBorder
              name={`claimAltAmount-${props.claimIndex}_${props.probabilityIndex}`}
              onChange={(e: any) =>
                handleQuantumValueChange(e.target.value, e.target.id, 'amount')
              }
              error={
                props.errors.includes(
                  `claimAltAmount-${props.claimIndex}_${props.probabilityIndex}`,
                ) ||
                props.errors.includes(
                  `claimAltAmount-${props.claimIndex}_${props.probabilityIndex}:validation`,
                )
              }
              errorMessage={
                props.errors.includes(
                  `claimAltAmount-${props.claimIndex}_${props.probabilityIndex}:validation`,
                )
                  ? getText('error-32', user.settings)
                  : getText('error-21', user.settings)
              }
              errorTextAttribute={
                props.errors.includes(
                  `claimAltAmount-${props.claimIndex}_${props.probabilityIndex}:validation`,
                )
                  ? 'error-32'
                  : 'error-21'
              }
              optionsList={[
                currentClaim.amount
                  ? formattedNumToString(
                      Math.abs(
                        roundNumberTo(
                          (getAbs(currentClaim.amount!) * 3) / 4,
                          3,
                        ),
                      ),
                      user.settings,
                    )
                  : '0',
                currentClaim.amount
                  ? formattedNumToString(
                      Math.abs(
                        roundNumberTo(
                          (getAbs(currentClaim.amount!) * 2) / 4,
                          3,
                        ),
                      ),
                      user.settings,
                    )
                  : '0',
                currentClaim.amount
                  ? formattedNumToString(
                      Math.abs(
                        roundNumberTo(
                          (getAbs(currentClaim.amount!) * 1) / 4,
                          3,
                        ),
                      ),
                      user.settings,
                    )
                  : '0',
              ]}
              localStyle={
                !currentClaim.hasOutOfCourt && props.innerWidthState < 1230
                  ? {
                      marginTop: '21px !important',
                      borderColor:
                        objects.percentagesColors[props.probabilityIndex],
                    }
                  : {
                      borderColor:
                        objects.percentagesColors[props.probabilityIndex],
                    }
              }
              disabled={props.ownRole === AccessRole.VIEWER}
            />
          </div>
        )
      }

      {currentClaim.hasOutOfCourt ? (
        <div
          className="singleExternalConsequenceContainer"
          id={`claimSingleExternalConsequenceContainer-${props.claimIndex}_${props.probabilityIndex}`}
          style={props.smallerWidth ? { width: '400px' } : undefined}
        >
          {currentQuantum.outOfCourtSignum !== 'off' ? (
            <>
              <div
                className="externalConsequenceAmountContainer"
                id={`addRemoveExternal-${props.claimIndex}_${props.probabilityIndex}`}
              >
                <InputNum
                  label={getText('label-95', user.settings, partiesFormat)}
                  labelTextAttribute="label-95"
                  suffix={scenarioSnapshot.currentSnapshot.currency}
                  width={230}
                  value={
                    currentQuantum.outOfCourtAmount !== undefined
                      ? Math.abs(currentQuantum.outOfCourtAmount)
                      : undefined
                  }
                  id={`claimOutOfCourtAmount-${props.claimIndex}_${props.probabilityIndex}`}
                  className={`outOfCourtAmount${props.claimIndex + 1}-${
                    props.probabilityIndex
                  } amounts`}
                  placeholderPadding="65px"
                  name={`claimOutOfCourtAmount-${props.claimIndex}_${props.probabilityIndex}`}
                  maxValue={99999999999}
                  maxNumberOfDecimals={3}
                  onChange={(e: any) =>
                    handleQuantumValueChange(
                      e.target.value,
                      e.target.id,
                      'outOfCourtAmount',
                    )
                  }
                  tabIndex={8}
                  error={props.errors.includes(
                    `claimOutOfCourtAmount-${props.claimIndex}_${props.probabilityIndex}`,
                  )}
                  errorMessage={getText('error-21', user.settings)}
                  errorTextAttribute="error-21"
                  readOnly={props.ownRole === AccessRole.VIEWER}
                  disabled={props.ownRole === AccessRole.VIEWER}
                  prefix={
                    <div style={{ width: '15px' }}>
                      <Symbol
                        symbolType={
                          currentQuantum.outOfCourtSignum === AmountSignum.get
                            ? 'positiveExternalClient'
                            : 'negativeExternalClient'
                        }
                        targetId={`claimOutOfCourtAmount-${props.claimIndex}_${props.probabilityIndex}`}
                        targetType={'input'}
                      />
                    </div>
                  }
                  localStyle={{
                    color: krogerusColors
                      ? KROGERUS_CLIENT_COLOR
                      : CLIENT_COLOR,
                  }}
                  onMouseEnter={() => {
                    setShowDeleteImg(true)
                  }}
                  onMouseLeave={() => {
                    setShowDeleteImg(false)
                  }}
                />
                {props.ownRole !== AccessRole.VIEWER && showDeleteImg && (
                  <img
                    id={`removeOutOfCourtAmount-${props.claimIndex}_${props.probabilityIndex}`}
                    src={deleteBucket}
                    alt="deleteBucket"
                    onClick={() =>
                      handleOutOfCourtSignumChange(
                        'off',
                        `addRemoveExternal-${props.claimIndex}_${props.probabilityIndex}`,
                      )
                    }
                    onMouseEnter={() => {
                      setShowDeleteImg(true)
                      secondaryHover.current = `remove $outOfCourtAmount${
                        props.claimIndex + 1
                      }-${props.probabilityIndex}`
                      setTimeout(() => {
                        if (
                          secondaryHover.current ===
                          `remove $outOfCourtAmount${props.claimIndex + 1}-${
                            props.probabilityIndex
                          }`
                        ) {
                          setHoverElement(
                            `remove $outOfCourtAmount${props.claimIndex + 1}-${
                              props.probabilityIndex
                            }`,
                          )
                        }
                      }, 1000)
                    }}
                    onMouseLeave={() => {
                      setShowDeleteImg(false)
                      secondaryHover.current = 'close'
                      setHoverElement('')
                    }}
                  />
                )}
                {hoverElement ===
                `remove $outOfCourtAmount${props.claimIndex + 1}-${
                  props.probabilityIndex
                }` ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '-10px', left: '250px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-18', user.settings)}
                      textAttribute="hover-18"
                      id={`remove $outOfCourtAmount${props.claimIndex + 1}-${
                        props.probabilityIndex
                      }`}
                    />
                  </div>
                ) : null}
              </div>
              <div className="greenRedOutOfCourt">
                <SwitchButton
                  width="230px"
                  label1={
                    getText('switchButton-3', user.settings, partiesFormat)
                      .length > 26
                      ? getText('switchButton-3', user.settings, partiesFormat)
                          .substring(0, 26)
                          .trim() + '...'
                      : getText('switchButton-3', user.settings, partiesFormat)
                  }
                  label1TextAttribute="switchButton-3"
                  label2={
                    getText('switchButton-4', user.settings, partiesFormat)
                      .length > 26
                      ? getText('switchButton-4', user.settings, partiesFormat)
                          .substring(0, 26)
                          .trim() + '...'
                      : getText('switchButton-4', user.settings, partiesFormat)
                  }
                  label2TextAttribute="switchButton-4"
                  onClick={handleSwitchOutOfCourtSignum}
                  id={`switchClaimOutOfCourtSignum-${props.claimIndex}_${props.probabilityIndex}`}
                  id1={`switchClaimOutOfCourtSignumState1-${props.claimIndex}_${props.probabilityIndex}`}
                  id2={`switchClaimOutOfCourtSignumState2-${props.claimIndex}_${props.probabilityIndex}`}
                  defaultState={
                    currentQuantum.outOfCourtSignum === 'get' ? 1 : 2
                  }
                  backgroundColor1="#FFFFFF"
                  backgroundColor2="#FFFFFF"
                  marginLabel1="0 0 0 35px"
                  marginLabel2="0 0 0 10px"
                  disabled={props.ownRole === AccessRole.VIEWER}
                />
              </div>
            </>
          ) : props.ownRole !== AccessRole.VIEWER ? (
            <div
              className="single-quantum-add-button-container"
              id={`addRemoveExternal-${props.claimIndex}_${props.probabilityIndex}`}
              onClick={() =>
                handleOutOfCourtSignumChange(
                  'get',
                  `addRemoveExternal-${props.claimIndex}_${props.probabilityIndex}`,
                )
              }
            >
              <div className="addSignContainer">
                <img src={addSignImg} alt="removeSignImg" />
              </div>
              <p
                id={`outOfCourtSubmenuTitleOff-${props.claimIndex}_${props.probabilityIndex}`}
                data-textattribute="submenu-7"
              >
                {getText('submenu-7', user.settings)}
              </p>
            </div>
          ) : null}
        </div>
      ) : null}

      <div
        className="probabilityContainer"
        style={
          !currentClaim.hasOutOfCourt && props.innerWidthState < 1230
            ? { marginLeft: '20px', marginTop: '21px !important' }
            : undefined
        }
      >
        {hoverElement === 'fullLossProbHover' ? (
          <div
            className="hoverComponentContainer"
            style={{ top: '70px', left: '30px' }}
          >
            <HoverComponent
              hoverText={
                currentClaim.quantumProbabilities.length === 1
                  ? getText('hover-19', user.settings)
                  : getText('hover-20', user.settings)
              }
              textAttribute={
                currentClaim.quantumProbabilities.length === 1
                  ? 'hover-19'
                  : 'hover-20'
              }
              id="fullLossProbHover"
            />
          </div>
        ) : null}
        <InputNum
          label={getText('label-23', user.settings)}
          labelTextAttribute="label-23"
          suffix="%"
          defaultZero
          localStyle={
            props.probabilityIndex ===
            currentClaim.quantumProbabilities.length - 1
              ? { color: '#808080', cursor: 'default' }
              : undefined
          }
          width={92}
          readOnly={
            props.probabilityIndex ===
            currentClaim.quantumProbabilities.length - 1
              ? true
              : false
          }
          disabled={
            props.ownRole === AccessRole.VIEWER &&
            props.probabilityIndex !==
              currentClaim.quantumProbabilities.length - 1
          }
          maxValue={getMaxForProb(
            currentClaim.quantumProbabilities,
            props.probabilityIndex,
          )}
          maxNumberOfDecimals={0}
          value={props.currentProbability}
          borderColor={
            props.probabilityIndex ===
            currentClaim.quantumProbabilities.length - 1
              ? 'transparent'
              : objects.percentagesColors[props.probabilityIndex]
          }
          tagcolor={objects.percentagesColors[props.probabilityIndex]}
          tabIndex={
            props.probabilityIndex ===
            currentClaim.quantumProbabilities.length - 1
              ? -1
              : 9
          }
          boldBorder
          className="probability amounts"
          id={`claimQuantumProbability-${props.claimIndex}_${props.probabilityIndex}`}
          name={`claimQuantumProbability-${props.claimIndex}_${props.probabilityIndex}`}
          onChange={(e: any) =>
            handleQuantumValueChange(e.target.value, e.target.id, 'probability')
          }
          onClick={
            props.probabilityIndex ===
            currentClaim.quantumProbabilities.length - 1
              ? () => {
                  setHoverElement('fullLossProbHover')
                }
              : undefined
          }
        />
      </div>
      {props.probabilityIndex !== 0 ? (
        <div
          className="deleteContainer"
          style={
            !currentClaim.hasOutOfCourt && props.innerWidthState < 1230
              ? { right: '-32px' }
              : props.smallerWidth && props.innerWidthState < 1230
              ? { right: '-25px' }
              : currentClaim.hasOutOfCourt && props.innerWidthState < 1230
              ? { right: '-30px' }
              : undefined
          }
        >
          {hoverElement ===
          `deleteClaimSingleQuantum-${props.claimIndex}_${props.probabilityIndex}` ? (
            <div
              className="hoverComponentContainer"
              style={{ top: '-10px', left: '30px' }}
            >
              <HoverComponent
                hoverText={getText('hover-21', user.settings)}
                textAttribute="hover-21"
                id={`deleteClaimSingleQuantum-${props.claimIndex}_${props.probabilityIndex}`}
                style={{
                  width: '120px',
                  padding: '4px 20px',
                }}
              />
            </div>
          ) : null}
          {props.ownRole !== AccessRole.VIEWER && (
            <img
              src={deleteImg}
              alt="deleteImg"
              id={`deleteClaimSingleQuantum-${props.claimIndex}_${props.probabilityIndex}`}
              onClick={handleRemoveSingleQuantum}
              onMouseEnter={() => {
                secondaryHover.current = `deleteClaimSingleQuantum-${props.claimIndex}_${props.probabilityIndex}`
                setTimeout(() => {
                  if (
                    secondaryHover.current ===
                    `deleteClaimSingleQuantum-${props.claimIndex}_${props.probabilityIndex}`
                  ) {
                    setHoverElement(
                      `deleteClaimSingleQuantum-${props.claimIndex}_${props.probabilityIndex}`,
                    )
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  )
}
